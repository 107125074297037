import React, { useEffect, useRef, useState } from 'react'
import Button from '@/Shared/Button'
import { router } from '@inertiajs/react'
import { useForm, usePage } from '@inertiajs/react'
import Select from '@/Shared/Forms/Select'
import SlideOver from '@/Shared/SlideOver'
import Helpers from '../../utils/helpers'

export default ({ open, onClosed }) => {
  let focusRef = useRef(null)
  const { auth, filters, propertyTypes, states, allStates, types } = usePage().props
  const { user } = auth
  const availableStates = Helpers.isAdminOrHR(user) ? allStates : states
  const [stages, setStages] = useState([])
  const statuses = [
    { value: 'Archived', label: 'Archived' },
    { value: 'Closed', label: 'Closed' },
    { value: 'Compliance', label: 'Compliance' },
    { value: 'Completed', label: 'Completed' },
    { value: 'Lost', label: 'Lost' },
    { value: 'On-Hold', label: 'On-Hold' },
    { value: 'Open', label: 'Open' },
    { value: 'Pending', label: 'Pending' },
    { value: 'Under Review', label: 'Under Review' },
  ]

  const { data, setData } = useForm({
    associates: [],
    property_types: [],
    stage: '',
    statuses: ['Open'],
    states: [],
    type: '',
  })

  useEffect(() => {
    if (open) {
      setData({
        associates: filters.associates || [],
        property_types: filters.property_types || [],
        stage: filters.stage || '',
        statuses: filters.statuses || [],
        states: filters.states || [],
        type: filters.type || '',
      })
    }
  }, [open])

  const fetchAssociates = (value) =>
    axios.get('/api/contacts', {
      params: {
        limit: 25,
        search: value,
        groups: 'associates',
      },
    })

  const fetchStages = (checklist_id) => {
    if (checklist_id) {
      axios.get(route('api.checklists.sections.index'), { params: { checklist_id: checklist_id } }).then((response) => {
        setStages(response.data)
      })
      setData({ ...data, type: checklist_id })
    } else {
      setData({ ...data, type: '', stage: '' })
    }
  }

  const showOption = (option) => (
    <div className="group flex items-center space-x-3" key={option.id}>
      {option.avatar ? (
        <img className="h-10 w-10 rounded-full" src={option.avatar} alt="" />
      ) : (
        <div className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-200 text-gray-700">
          <div className="text-base font-medium tracking-wide">{option.initials}</div>
        </div>
      )}

      <div>
        <div className="space-x-1">
          <span className="space-x-1 font-medium text-gray-900">
            <span>{option.name}</span>
            {option.industry && <span className="text-gray-500">in {option.industry}</span>}
          </span>
        </div>

        {option.company && <div className="text-gray-500">{option.company}</div>}
      </div>
    </div>
  )

  const onSubmit = (e) => {
    e.preventDefault()

    const newFilters = (({ trashed, ...o }) => o)(data)
    const updatedFilters = {
      ...filters,
      ...newFilters,
    }
    const query = encodeURIComponent(JSON.stringify(updatedFilters))

    router.get(
      route(route().current(), {
        filters: query,
      }),
      {},
      {
        preserveState: true,
        onStart: () => onClosed(),
      },
    )
  }

  return (
    <SlideOver
      focusRef={focusRef}
      footerActions={
        <Button theme="solid" type="submit" form="filters">
          Apply
        </Button>
      }
      show={open}
      title="Filters"
      onClosed={onClosed}
      aboveMessages
    >
      <form id="filters" className="flex h-full flex-col" onSubmit={onSubmit}>
        {Helpers.isAdminOrHR(user) && (
          <Select
            ref={focusRef}
            label="Associate(s)"
            name="associates"
            value={data.associates}
            optionLabel={(option) => !option.label && showOption(option)}
            optionValue={(option) => option.id}
            placeholder="Search Associates"
            onChange={(selected) => setData({ ...data, associates: selected })}
            onInputChanged={(value) => fetchAssociates(value)}
            multiple
            async
          />
        )}

        <Select
          ref={!Helpers.isAdminOrHR(user) ? focusRef : undefined}
          label="Property Type(s)"
          name="property_types"
          options={propertyTypes}
          placeholder="Any"
          value={propertyTypes.filter((p) => data.property_types.find((f) => f === p.value))}
          onChange={(selected) => setData({ ...data, property_types: selected ? Array.from(selected, (s) => s.value) : [] })}
          multiple
        />

        <Select
          label="State(s)"
          name="states"
          options={availableStates}
          placeholder="Any"
          value={availableStates.filter((p) => data.states.find((f) => f === p.value))}
          onChange={(selected) => setData({ ...data, states: selected ? Array.from(selected, (s) => s.value) : [] })}
          multiple
        />

        <Select
          label="Status(es)"
          name="statuses"
          options={statuses}
          placeholder="Any"
          value={statuses.filter((p) => data.statuses.find((f) => f === p.value))}
          onChange={(selected) => setData({ ...data, statuses: selected ? Array.from(selected, (s) => s.value) : [] })}
          multiple
        />

        <Select
          label="Type"
          name="type"
          options={types}
          placeholder="Any"
          value={data.type}
          onChange={(selected) => fetchStages(selected?.value)}
        />

        <Select
          label="Stage"
          name="stage"
          options={stages}
          placeholder="Any"
          value={data.stage}
          onChange={(selected) => setData({ ...data, stage: selected?.value })}
          disabled={!Boolean(data.type)}
        />
      </form>
    </SlideOver>
  )
}
