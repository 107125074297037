import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { BlockUIContext } from '@/Shared/BlockUI/BlockUIContext'
import { useForm, usePage } from '@inertiajs/react'
import Button from '@/Shared/Button'
import Checkbox from '@/Shared/Forms/Checkbox'
import Heading from '@/Shared/Forms/Heading'
import Select from '@/Shared/Forms/Select'
import SlideOver from '@/Shared/SlideOver'
import TextInput from '@/Shared/Forms/TextInput'

export default ({ contact, open, onClosed }) => {
  const { checklists, propertyTypes, states } = usePage().props
  let focusRef = useRef()

  const { data, setData, post, clearErrors, errors, reset } = useForm()
  const [type, setType] = useState(null)
  const [addresses, setAddresses] = useState(null)
  const { loading, setLoading } = useContext(BlockUIContext)

  useEffect(() => {
    let initial = {
      id: null,
      contact: contact,
      contact_id: contact ? contact.id : null,
      checklist_id: null,
      name: '',
      property_types: null,
      states: null,
      address_id: null,
      property_street: '',
      property_street2: '',
      property_city: '',
      property_state: '',
      property_postal_code: '',
      property_built_year: null,
      import_address: true,
    }

    setData(initial)
  }, [])

  useEffect(() => {
    if (open) {
      setAddresses(
        contact?.addresses
          .filter((address) => states.find((state) => state.value === address.state))
          .map((address) => ({
            value: address.id,
            label: `${address.address_inline}` + (address.type ? ` (${address.type})` : ''),
          }))
          .concat({ value: 'new', label: 'New Address' }),
      )
    }
  }, [open])

  useEffect(() => {
    if (data.checklist_id) {
      setType(checklists.find((c) => c.value == data.checklist_id).label)
    }
  }, [data])

  useEffect(() => {
    if (addresses) {
      setData({
        ...data,
        address_id: addresses.length > 1 ? null : 'new',
        property_street: '',
        property_street2: '',
        property_city: '',
        property_state: '',
        property_postal_code: '',
        import_address: [null, 'new'].some((value) => value == data.address_id),
      })
    }
  }, [addresses])

  const fetchContacts = (value) =>
    axios.get('/api/contacts', {
      params: {
        limit: 25,
        search: value,
        output: 'grouped',
      },
    })

  const fetchContactAddresses = (contact_id) => {
    axios.get(`/api/contacts/${contact_id}/addresses`).then((response) => {
      if (response.data.length > 0) {
        setAddresses(
          response.data
            .filter((address) => states.find((state) => state.value === address.state))
            .concat({ value: 'new', label: 'New Address' }),
        )
      } else {
        setAddresses([{ value: 'new', label: 'New Address' }])
      }
    })
  }

  const onContactChanged = (selected) => {
    setData({
      ...data,
      contact_id: selected?.id,
      contact: selected?.name,
    })

    if (selected?.id) {
      fetchContactAddresses(selected?.id)
    }
  }

  const submit = (event) => {
    event.preventDefault()

    setLoading(true)

    post(route('transactions.store'), {
      onSuccess: (response) => {
        clearErrors()
        reset()
        onClosed(null, response.props.transaction)
      },
      onFinish: () => {
        setLoading(false)
      },
    })
  }

  return (
    <Fragment>
      <SlideOver
        focusRef={focusRef}
        footerActions={
          <Button type="submit" theme="solid" form="transaction-form" disabled={loading}>
            {data.id ? 'Save Changes' : 'Continue'}
          </Button>
        }
        show={open}
        size="max-w-lg"
        title="New"
        subTitle="Transaction"
        onClosed={onClosed}
        aboveMessages
      >
        <form id="transaction-form" className="pb-4" onSubmit={submit}>
          <Select
            ref={focusRef}
            error={errors.checklist_id}
            label="Transaction Type"
            name="checklist_id"
            options={checklists}
            placeholder="Choose one"
            value={data.checklist_id}
            onChange={(selected) => setData({ ...data, checklist_id: selected && selected.value })}
            isClearable={false}
            required
          />

          {!contact && data.checklist_id && (
            <div className="mb-3 space-y-1.5">
              <Select
                error={errors.checklist_id}
                label={type ? `Primary ${type}` : 'Contact'}
                name="contact_id"
                info={
                  data.contact
                    ? ''
                    : 'You must create a contact before you can create a transaction.  Additional contacts can be added once the transaction has been created.'
                }
                optionLabel={(option) =>
                  option.label || (
                    <div className="group flex items-center space-x-3">
                      {option.avatar ? (
                        <img className="h-10 w-10 rounded-full" src={option.avatar} alt="" />
                      ) : (
                        <div className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-200 text-gray-700">
                          <div className="text-base font-medium tracking-wide">{option.initials}</div>
                        </div>
                      )}

                      <div>
                        <div className="space-x-1">
                          <span className="space-x-1 font-medium text-gray-900">
                            <span>{option.name}</span>
                            {option.industry && <span className="text-gray-500">in {option.industry}</span>}
                          </span>
                        </div>

                        {option.company && <div className="text-gray-500">{option.company}</div>}
                      </div>
                    </div>
                  )
                }
                optionValue={(option) => option.id}
                placeholder="Search contacts"
                value={data.contact}
                onChange={(selected) => onContactChanged(selected)}
                onInputChanged={(value) => fetchContacts(value)}
                required
                async
              />
            </div>
          )}

          {(data.id || data.contact) && (
            <Fragment>
              <Heading>General Information</Heading>

              <Select
                error={errors.property_types}
                label="Property Type(s)"
                name="property_types"
                options={propertyTypes}
                placeholder="Choose one"
                value={data.property_types}
                onChange={(selected) => setData({ ...data, property_types: Array.isArray(selected) ? selected : [selected] })}
                isClearable={false}
                multiple={['Buyer', 'Tenant'].some((t) => type === t)}
                required
              />

              {data.checklist_id && ['Buyer', 'Tenant'].some((t) => type === t) && (
                <Select
                  error={errors.states}
                  label="State(s)"
                  name="property_type"
                  options={states}
                  placeholder="Choose one or more"
                  value={data.states}
                  onChange={(selected) => setData({ ...data, states: selected })}
                  isClearable={false}
                  multiple
                  required
                />
              )}

              {type !== null && !['Buyer', 'Tenant'].some((t) => type === t) && (
                <Fragment>
                  <Heading>Property Information</Heading>

                  {addresses?.length > 1 && (
                    <Select
                      error={errors.address_id}
                      label="Address"
                      name="address_id"
                      options={addresses}
                      placeholder="Choose one"
                      value={data.address_id}
                      onChange={(selected) => {
                        setData({
                          ...data,
                          address_id: selected?.__isNew__ ? 'new' : selected?.value,
                          property_street: selected?.__isNew__ ? selected?.value : data.property_street,
                          import_address: [null, 'new'].some((value) => value == (selected?.__isNew__ ? 'new' : selected?.value)),
                        })
                      }}
                      isClearable={false}
                      required
                      creatable
                    />
                  )}

                  {(addresses?.length == 1 || data.address_id === 'new') && (
                    <div className="mb-4 space-y-5">
                      <div>
                        <TextInput
                          error={errors.property_street}
                          classes="mb-1"
                          name="property_street"
                          value={data.property_street ?? ''}
                          placeholder="Street address"
                          onChange={(value) => setData({ ...data, property_street: value })}
                        />
                        <TextInput
                          classes="mb-1"
                          name="property_street2"
                          value={data.property_street2 ?? ''}
                          placeholder="Street address line 2"
                          onChange={(value) => setData({ ...data, property_street2: value })}
                        />
                        <TextInput
                          error={errors.property_city}
                          classes="mb-1"
                          name="property_city"
                          value={data.property_city ?? ''}
                          placeholder="City"
                          onChange={(value) => setData({ ...data, property_city: value })}
                        />
                        <Select
                          error={errors.property_state}
                          classes="mb-1"
                          name="property_state"
                          options={states}
                          placeholder="State"
                          value={data.property_state}
                          isClearable={false}
                          onChange={(selected) => setData({ ...data, property_state: selected?.value })}
                        />
                        <TextInput
                          error={errors.property_postal_code}
                          classes="mb-1"
                          name="property_postal_code"
                          value={data.property_postal_code ?? ''}
                          placeholder="ZIP code"
                          onChange={(value) => setData({ ...data, property_postal_code: value })}
                        />
                      </div>

                      <Checkbox
                        name="import_address"
                        label="Add address to Seller's contact record"
                        value={data.import_address}
                        onChange={(checked) => setData({ ...data, import_address: checked })}
                        centered
                      />
                    </div>
                  )}
                </Fragment>
              )}
            </Fragment>
          )}
        </form>
      </SlideOver>
    </Fragment>
  )
}
