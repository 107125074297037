import MainLayout from '@/Shared/Layouts/Main'
import Button from '../Shared/Button'

const ApplicationError = () => {
  return (
    <div className="flex h-full items-center justify-center bg-gray-300 text-2xl">
      <div className="flex flex-col justify-center rounded-xl bg-white p-8">
        <div className="flex max-w-2xl items-center">
          <div className="space-y-4">
            <div className="text-center text-5xl font-medium text-red-600">500 ERROR</div>

            <div className="space-y-6 text-2xl leading-snug text-gray-700">
              <p className="text-red-600">
                Sorry, something went wrong on our end. A report has been created for this error and sent to our support team.
              </p>
              <p className="text-center">Submit a ticket to request help with this issue.</p>
            </div>

            <div className="flex justify-center">
              <Button theme="solid" colors="bg-red-500 text-white" className="p-6" size="" onClick={() => FreshworksWidget('open')}>
                <i className="far fa-question-circle mr-3 w-7 text-center text-xl leading-none"></i>
                Request Support
              </Button>
            </div>
          </div>
        </div>

        <div className="mt-8 flex flex-col items-center justify-center">
          <span>
            <svg className="mb-4 w-24 fill-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 91.07 120.42">
              <path
                fillOpacity=".55"
                d="M42.62,24A117.22,117.22,0,0,1,61.23,36.52a51.42,51.42,0,0,1,17.3,26.39C82.29,43.57,71.68,26.5,91.07,0,66,5.25,54.05,12.84,42.62,24Z"
              ></path>
              <path
                fillOpacity=".9"
                d="M58.37,40.23C42.65,27.88,30,20.35,0,18.77,23.86,42.62,14.9,61.46,22.61,80.54a28.2,28.2,0,0,0,34.6,16.84c2.84,8,3.61,16.45,1.18,21.29,0,3.31,5.8,1.38,8.56-1.38,2.39-2.39-2-14.89-6.31-21.26A27.4,27.4,0,0,0,73.25,83.3C79,71.87,72.57,51.41,58.37,40.23Z"
              ></path>
              <path
                fill="#ffffff"
                d="M37.17,60.6C33,44.7,26.17,33.69,25.69,34c.5-.27,11.81,8.34,16,24.23s11,26.91,11.47,26.64C52.61,85.1,41.31,76.5,37.17,60.6Z"
              ></path>
            </svg>
          </span>
        </div>
      </div>
    </div>
  )
}

ApplicationError.layout = (page) => <MainLayout title="Application Error" children={page} />

export default ApplicationError
