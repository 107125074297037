import { Fragment, useContext, useEffect, useState } from 'react'
import { router } from '@inertiajs/react'

import AccordionCard from '@/Shared/AccordionCard'
import Button from '@/Shared/Button'
import ChangeAssociateSideSplitsDialog from './ChangeAssociateSideSplitsDialog'
import Contact from './Contact'
import Dialog from '@/Shared/Dialog/Index'
import Dropdown from '@/Shared/Dropdown'
import DropdownItem from '@/Shared/DropdownItem'
import Preview from '@/Pages/Contacts/Preview'
import SimilarContacts from '@/Shared/SimilarContacts'
import { usePage } from '@inertiajs/react'
import { ContactContext } from './ContactContext'
import Helpers from '@/utils/helpers'
import classNames from 'classnames'

export default ({ group, transaction, onSearching }) => {
  const { user } = usePage().props.auth
  const administrating = Helpers.isAdminOrHR(user)
  const { contacts } = transaction
  const { onEditing } = useContext(ContactContext)
  const groupContacts = contacts
    .filter((contact) => contact.party_representing?.find((pr) => pr === group))
    .sort((a, b) => {
      if (a.type === 'Associate' && b.type !== 'Associate') {
        return -1
      } else if (a.type !== 'Associate' && b.type === 'Associate') {
        return 1
      } else {
        a = a.contact?.last_name || a.last_name
        b = b.contact?.last_name || b.last_name

        return a.localeCompare(b)
      }
    })
  const clients = groupContacts.filter((contact) => contact.roles?.find((r) => r.name === group))
  const activeContacts = groupContacts.filter(
    (contact) => !contact.pending && (contact.roles.length == 0 || contact.roles?.find((r) => r.name !== group)),
  )
  const myContact = transaction.contacts.find((c) => c.is_me)
  const leadAgent = transaction.contacts.find((c) => c.is_transaction_owner)
  const pendingContacts = groupContacts.filter((contact) => contact.roles?.find((r) => r.name !== group) && contact.pending)
  const [contact, setContact] = useState(null)
  const [removingTeamMember, setRemovingTeamMember] = useState(false)
  const [contactPreviewing, setContactPreviewing] = useState(null)
  const [contactPromoting, setContactPromoting] = useState(false)
  const [contactRemoving, setContactRemoving] = useState(null)

  useEffect(() => {
    previewContact(contacts.find((c) => c.id == contactPreviewing?.id))
  }, [transaction])

  const addContact = (id) => {
    axios
      .post(`/api/contacts`, {
        contact_id: id,
      })
      .then(() => {
        router.visit(window.location.href, {
          preserveScroll: true,
          preserveState: true,
          replace: true,
          onFinish: () => setContactPreviewing(null),
        })
      })
  }

  const handleEditing = (contact) => {
    setContactPreviewing(null)

    setTimeout(() => {
      onEditing(contact)
    }, 100)
  }

  const previewContact = (contact) => {
    if (contact) {
      let first_name = contact.first_name || contact.contact?.first_name || ''
      let nickname = contact.nickname || contact.contact?.nickname || ''
      let middle_name = contact.middle_name || contact.contact?.middle_name || ''
      let last_name = contact.last_name || contact.contact?.last_name || ''
      let suffix = contact.suffix || contact.contact?.suffix || ''

      setContact({
        ...contact,
        ...{
          transaction_id: transaction.id,
          type: contact.contact_type,
          party_representing: contact.party_representing,
          roles: contact.roles,
          permissions: contact.permissions,
          full_name:
            (nickname || first_name) + (middle_name && ` ${middle_name}`) + (last_name && ` ${last_name}`) + (suffix && ` ${suffix}`),
        },
      })

      setContactPreviewing({
        ...contact,
        ...{
          id: contact.id,
          transaction_id: transaction.id,
          type: contact.contact_type,
          party_representing: contact.party_representing,
          roles: contact.roles,
          permissions: contact.permissions,
          first_name: first_name,
          nickname: nickname,
          middle_name: middle_name,
          last_name: last_name,
          suffix: suffix,
          full_name:
            (nickname || first_name) + (middle_name && ` ${middle_name}`) + (last_name && ` ${last_name}`) + (suffix && ` ${suffix}`),
          company: contact.company || contact.contact?.company || '',
          title: contact.title || contact.contact?.title || '',
          industry: contact.industry || contact.contact?.industry || '',
          street: contact.street || contact.contact?.street || '',
          street2: contact.street2 || contact.contact?.street2 || '',
          city: contact.city || contact.contact?.city || '',
          state: contact.state || contact.contact?.state || '',
          postal_code: contact.postal_code || contact.contact?.postal_code || '',
          address_inline: contact.address_inline || contact.contact?.address_inline || '',
          address_query: contact.address_query || contact.contact?.address_query || '',
          phone: contact.phone || contact.contact?.phone || '',
          phone_formatted: contact.phone_formatted || contact.contact?.phone_formatted || '',
          phone_type: contact.phone_type || contact.contact?.phone_type || '',
          email: contact.email || contact.contact?.email || '',
        },
      })
    }
  }

  const resendInvitation = (invitee) => {
    axios.post(route('transactions.invitations.resend', { transaction: transaction.id, transactionContact: invitee.id })).then(() => {
      router.visit(window.location.href, {
        preserveScroll: true,
        preserveState: false,
        replace: true,
        onFinish: () => setContactPreviewing(null),
      })
    })
  }

  const upgradeToPRO = (pro) => {
    router.post(
      route('api.transactions.contacts.upgrade', { transaction: transaction.id, transactionContact: contactPreviewing.id }),
      {
        contact_id: pro.id,
      },
      {
        onFinish: () => setTimeout(() => setContactPreviewing(null), 50),
      },
    )
  }

  const beforeRemoving = (transactionContact) => {
    setContactRemoving(transactionContact)

    if (
      transaction.contacts.filter((tc) => tc.contact_type === 'Associate').length > 2 &&
      transactionContact?.contact?.type === 'Associate' &&
      transactionContact.party_representing.find((p) => p === transaction.type)
    ) {
      setRemovingTeamMember(true)
    } else {
      removeContact(transactionContact)
    }
  }

  const handleChangePermissions = (permission) => {
    router.post(
      route('api.transactions.contacts.change-permissions', { transaction: transaction.id, transactionContact: contactPreviewing.id }),
      { permission: permission },
      {
        onFinish: () => {
          setContactPreviewing(null)
        },
      },
    )
  }

  const promote = () => {
    router.post(
      route('api.transactions.contacts.promote', { transaction: transaction.id, transactionContact: contactPreviewing.id }),
      {},
      {
        onFinish: () => {
          setContactPromoting(false)
          setTimeout(() => {
            setContactPreviewing(null)
          }, 50)
        },
      },
    )
  }

  const removeContact = (transactionContact, splits) => {
    setRemovingTeamMember(false)

    axios
      .delete(route('transactions.contacts.destroy', { transaction: transaction.id, transactionContact: transactionContact.id }), {
        data: splits,
      })
      .then(() => {
        setContactRemoving(null)
        setContactPreviewing(null)

        router.visit(window.location.href, {
          preserveScroll: true,
          preserveState: true,
          replace: true,
        })
      })
  }

  return (
    <AccordionCard
      title={`${group} Contacts (${groupContacts.length || 0})`}
      actions={
        !transaction.restricted &&
        transaction.editable && (
          <Button theme="link" onClick={() => onSearching({ group: group })}>
            <i className="far fa-plus pr-1 text-sm"></i>
            <span>Add</span>
          </Button>
        )
      }
    >
      <Fragment>
        {groupContacts.length > 0 && (
          <div className="space-y-6">
            {clients.length > 0 && (
              <div>
                <div className="mb-3 border-b border-gray-200 pb-0.5 font-medium text-gray-500">{group}</div>
                <div className="space-y-2">
                  {clients.map((client, index) => (
                    <Contact
                      contact={client}
                      transaction={transaction}
                      key={index}
                      onFollowContact={(id) => addContact(id)}
                      onPreview={() => previewContact(client)}
                    />
                  ))}
                </div>
              </div>
            )}

            {activeContacts.length > 0 && (
              <div>
                <div className="mb-3 border-b border-gray-200 pb-0.5 font-medium text-gray-500">{group} PRO Team</div>
                <div className="space-y-2">
                  {activeContacts.map((c, index) => (
                    <Contact
                      contact={c}
                      transaction={transaction}
                      key={c.id}
                      onFollowContact={(id) => addContact(id)}
                      onPreview={() => previewContact(c)}
                    />
                  ))}
                </div>
              </div>
            )}

            {pendingContacts.length > 0 && (
              <div>
                <div className="mb-3 flex items-center border-b border-gray-200 pb-0.5 font-medium text-gray-500">
                  <i className="fas fa-user-clock pb-1 pr-2 text-lg leading-none"></i>
                  Pending Invitations
                </div>
                <div className="space-y-2">
                  {groupContacts
                    .filter((c) => c.contact_type !== 'Client' && c.pending)
                    .map((c) => (
                      <Contact
                        contact={c}
                        transaction={transaction}
                        key={c.id}
                        onFollowContact={(id) => addContact(id)}
                        onPreview={() => previewContact(c)}
                      />
                    ))}
                </div>
              </div>
            )}
          </div>
        )}

        <Preview
          contact={contactPreviewing}
          open={contactPreviewing != null}
          onClosed={() => !contactPromoting && setContactPreviewing(null)}
          onEdit={(contact) => handleEditing(contact)}
          hideFooter={!contactPreviewing?.is_owner && !contactPreviewing?.followers?.find((f) => f.owner_id == user.id)}
          editable={contactPreviewing?.type !== 'Associate' && !transaction.restricted && transaction.editable}
        >
          {contactPreviewing &&
            contactPreviewing.type === 'Contact' &&
            contactPreviewing.following?.length == 0 &&
            contactPreviewing.similar?.length > 0 && (
              <SimilarContacts
                contacts={contactPreviewing.similar}
                subHeading="Upgrading this contact will instead display information maintained by the PRO."
                dismissable={false}
                vertical={true}
              >
                <SimilarContacts.Actions>
                  {({ contact }) => (
                    <Button type="button" theme="outline" onClick={() => upgradeToPRO(contact)}>
                      <div className="flex items-center gap-2">
                        <i className="fad fa-link-horizontal text-lg"></i>
                        Upgrade to this PRO
                      </div>
                    </Button>
                  )}
                </SimilarContacts.Actions>
              </SimilarContacts>
            )}

          {contactPreviewing &&
            !transaction.reviewing &&
            transaction.editable &&
            (Boolean(contactPreviewing.pending) ||
              contactPreviewing.contact_type !== 'Associate' ||
              myContact?.is_transaction_owner ||
              myContact?.permissions === 'admin') && (
              <Preview.Actions>
                {({ contact }) => (
                  <Dropdown id="actions-options" label="Actions" orientation="right" size="w-72" disabled={contact.is_transaction_owner}>
                    {Boolean(contact.pending) && (
                      <DropdownItem onClick={() => resendInvitation(contact)}>
                        <div className="w-8 text-left">
                          <i className="fas fa-envelope text-lg text-gray-500 group-hover:text-gray-600"></i>
                        </div>
                        Resend Invitation
                      </DropdownItem>
                    )}

                    {!Boolean(contact.pending) &&
                      !contactPreviewing.is_transaction_owner &&
                      !contactPreviewing.is_me &&
                      contactPreviewing.contact_type === 'Associate' && (
                        <DropdownItem onClick={() => setContactPromoting(true)}>
                          <div className="w-8 text-left">
                            <i className="fas fa-star text-lg text-orange-500"></i>
                          </div>
                          Promote to Lead Agent
                        </DropdownItem>
                      )}

                    {!Boolean(contact.pending) &&
                      !contactPreviewing.is_transaction_owner &&
                      (myContact?.is_transaction_owner || myContact?.permissions === 'admin') &&
                      ['editor', 'view'].indexOf(contactPreviewing?.permissions) >= 0 &&
                      contactPreviewing.contact_type === 'Associate' && (
                        <DropdownItem onClick={() => handleChangePermissions('admin')}>
                          <div className="w-8 text-left">
                            <i className="fas fa-user-shield text-lg text-blue-500"></i>
                          </div>
                          Promote to Admin
                        </DropdownItem>
                      )}

                    {!Boolean(contact.pending) &&
                      !contactPreviewing.is_transaction_owner &&
                      (myContact?.is_transaction_owner || myContact?.permissions === 'admin') &&
                      ['admin', 'view'].indexOf(contactPreviewing?.permissions) >= 0 &&
                      contactPreviewing.contact_type === 'Associate' && (
                        <DropdownItem onClick={() => handleChangePermissions('edit')}>
                          <div className="w-8 text-left">
                            <i
                              className={classNames(
                                'far fa-user-pen text-lg',
                                contactPreviewing.permissions === 'admin' ? 'text-red-700' : 'text-gray-500',
                              )}
                            ></i>
                          </div>
                          {contactPreviewing.permissions === 'admin' ? 'Demote' : 'Promote'} to Editor
                        </DropdownItem>
                      )}

                    {!Boolean(contact.pending) &&
                      !contactPreviewing.is_transaction_owner &&
                      (myContact?.is_transaction_owner || myContact?.permissions === 'admin') &&
                      contactPreviewing?.permissions !== 'view' &&
                      contactPreviewing.contact_type === 'Associate' && (
                        <DropdownItem onClick={() => handleChangePermissions('view')}>
                          <div className="w-8 text-left">
                            <i className="far fa-user-minus text-lg text-red-700"></i>
                          </div>
                          Demote to Viewer
                        </DropdownItem>
                      )}

                    {!Boolean(contact.pending) &&
                      contactPreviewing.contact_type === 'Contact' &&
                      contactPreviewing.roles.filter((role) => ['Buyer', 'Seller'].indexOf(role.name) >= 0 && !role.primary).length > 0 && (
                        <DropdownItem onClick={() => promote()}>
                          <div className="w-8 text-left">
                            <i className="fas fa-star text-lg text-orange-500"></i>
                          </div>
                          Promote to Primary {contactPreviewing.roles.find((role) => ['Buyer', 'Seller'].indexOf(role.name) >= 0).name}
                        </DropdownItem>
                      )}

                    {(contactPreviewing.contact_type !== 'Associate' ||
                      myContact?.is_transaction_owner ||
                      myContact?.permissions === 'admin') && (
                      <DropdownItem onClick={() => beforeRemoving(contact)}>
                        <div className="w-8 text-left">
                          <i className="far fa-user-minus text-gray-500 group-hover:text-gray-600"></i>
                        </div>
                        Remove from Transaction
                      </DropdownItem>
                    )}
                  </Dropdown>
                )}
              </Preview.Actions>
            )}

          <Preview.FooterActions>
            {({ contact, onVisit }) => {
              let myContact = contact.is_owner ? contact : contact.followers?.find((f) => f.owner_id == user.id)

              return (
                myContact?.id && (
                  <div className="flex justify-center">
                    <Button type="submit" theme="solid" onClick={() => onVisit(myContact.contact ? myContact.contact.id : myContact.id)}>
                      View My Contact
                    </Button>
                  </div>
                )
              )
            }}
          </Preview.FooterActions>
        </Preview>

        <ChangeAssociateSideSplitsDialog
          contactRemoving={contactRemoving}
          transaction={transaction}
          open={removingTeamMember}
          onClose={() => {
            setRemovingTeamMember(false)
            setContactRemoving(null)
          }}
          onApprove={(splits) => removeContact(contact, splits)}
        />

        <Dialog
          footerActions={
            <Button type="submit" theme="solid" onClick={() => promote()}>
              Continue
            </Button>
          }
          show={contactPromoting}
          title="Promote to Lead Agent"
          cancelText="Close"
          size="sm:max-w-xl"
          onClosed={() => setContactPromoting(false)}
          focusCancelButton
        >
          <div className="space-y-5">
            <p className="text-center text-lg font-semibold">
              You are about to promote <span className="text-blue-500">{(contactPreviewing?.contact || contactPreviewing)?.full_name}</span>{' '}
              to Lead Agent.
            </p>
            <div className="space-y-3 rounded-md border border-gray-300 bg-gray-50 p-4">
              <p>Continuing with this action will:</p>
              <ul className="ml-4 list-disc space-y-2">
                <li>
                  Make <b>{myContact?.is_transaction_owner ? 'YOU' : leadAgent.full_name}</b> a Co-Servicing Associate with <i>Edit</i>{' '}
                  permissions
                </li>
                <li>Require you to review & approve the Co-Servicing Agreement</li>
              </ul>
            </div>
          </div>
        </Dialog>
      </Fragment>
    </AccordionCard>
  )
}
