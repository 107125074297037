import React, { Fragment, useState } from 'react'
import Button from '@/Shared/Button'
import Filters from './Filters'
import TransactionCreate from './Create'
import SearchBar from '@/Shared/SearchBar'

export default () => {
  const [openDrawer, setOpenDrawer] = useState(null)

  return (
    <Fragment>
      <SearchBar
        actions={
          <Fragment>
            <Button theme="solid" onClick={() => setOpenDrawer('create')}>
              <i className="fas fa-plus pr-2 text-lg"></i>
              <span className="inline pr-1 lg:hidden xl:inline">New</span>
            </Button>
          </Fragment>
        }
        label="Transactions"
        sortOptions={[
          { value: 'created_at', label: 'Date Created', type: 'date' },
          { value: 'updated_at', label: 'Date Updated', type: 'date' },
          { value: 'closing_at', label: 'Date Closing', type: 'date' },
          { value: 'mortgage_commitment_date', label: 'Mortgage Commitment Date', type: 'date' },
          { value: 'name', label: 'Transaction Name', type: 'text' },
          { value: 'latest_stage', label: 'Current Stage', type: 'date' },
        ]}
        placeholder="Search name or client"
        onShowFilters={() => setOpenDrawer('filters')}
      />

      <Filters open={openDrawer === 'filters'} onClosed={() => setOpenDrawer(null)} />
      <TransactionCreate open={openDrawer === 'create'} onClosed={() => setOpenDrawer(null)} />
    </Fragment>
  )
}
