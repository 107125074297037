import { Fragment, useContext, useEffect, useState } from 'react'
import axios from '@/apis/axios'
import { BlockUIContext } from '@/Shared/BlockUI/BlockUIContext'
import classNames from 'classnames'
import { ContactConsumer } from '@/Pages/Transactions/People/ContactContext'
import { router } from '@inertiajs/react'
import useDebounce from '@/hooks/useDebounce'
import { useForm, usePage } from '@inertiajs/react'
import Button from '@/Shared/Button'
import Checkbox from '@/Shared/Forms/Checkbox'
import DynamicForm from './DynamicForm'
import FileAction from './Actions/File'
import RoleAction from './Actions/Role'

const RequiredFile = ({ name, associate, state }) => {
  return (
    <div className="inline-block rounded bg-red-100 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-red-600">
      {name} {state && <span>({state})</span>} file
      {associate && (
        <span>
          {' '}
          for <b>{associate}</b>
        </span>
      )}{' '}
      required
    </div>
  )
}

const Tag = ({ tag }) => {
  return (
    <div className="inline-block whitespace-nowrap rounded bg-gray-300/80 px-2.5 py-1.5 text-sm leading-none text-gray-800">
      <span>{tag.name}</span>
    </div>
  )
}

const Step = ({ associates, completedShown, disabled, editable, index, last, step, transaction, onEmailOpening, onFormOpening }) => {
  const { roles, states } = usePage().props
  const { setLoading } = useContext(BlockUIContext)
  const [initialRender, setInitialRender] = useState(true)
  const [completed, setCompleted] = useState(true)
  const [filesUploaded, setFilesUploaded] = useState(false)
  const { data, setData } = useForm()

  const role = step.actionable_type === 'Role' && roles.find((role) => step.actionable_id?.toString() === role.id)
  const incompleteSubtasks = step.subtasks?.length > 0 ? step.subtasks.filter((subtask) => !subtask.completed_at).length : 0
  const missingFields = getMissingFields()
  const missingRequiredEmailTemplateFiles = getMissingRequiredEmailTemplateFiles()
  const missingRoles = getMissingRoles()
  const missingSteps = getMissingSteps()
  const notApplicableFiles = step.actionable_type === 'File' ? step.files?.filter((file) => file.review_not_applicable) : []
  const declinedFiles = step.actionable_type === 'File' ? step.files?.filter((file) => file.declined) : []
  const fields = [
    { value: 'hiring_date', label: 'Hiring / Engagement Date' },
    { value: 'offer_to_purchase_date', label: 'Offer to Purchase Date' },
    { value: 'purchase_and_sale_date', label: 'Purchase and Sale Date' },
    { value: 'closing_at', label: 'Closing Date' },
    { value: 'closing_report_status', label: 'Closing Worksheet Status' },
    { value: 'closing_cost', label: transaction.type === 'Buyer' ? 'Seller Concessions' : 'Closing Cost Credit to Buyer' },
    { value: 'escrow_deposit1', label: 'Initial Escrow Deposit', type: 'Currency' },
    { value: 'escrow_deposit2', label: '2nd Escrow Deposit', type: 'Currency' },
    { value: 'commission_basis', label: 'Commission Based On' },
    { value: 'commission_buyer', label: "Buyer Agent's Compensation", type: 'Currency' },
    { value: 'commission_buyer_additional', label: "EXTRA Buyer Agent's Compensation", type: 'Currency' },
    { value: 'commission_seller', label: "Seller Agent's Compensation", type: 'Currency' },
    { value: 'total_purchase_price', label: 'Total Purchase Price', type: 'Currency' },
    { value: 'water_inspection_fee', label: 'Fee for Final Water Inspection and/or Bill' },
    { value: 'smoke_co_detector_inspection_fee', label: 'Fee for Smoke Detector and CO Detector Inspection' },
    { value: 'home_inspected_at', label: 'Home Inspection Date' },
    { value: 'home_inspected_time', label: 'Home Inspection Time' },
    { value: 'home_inspection_contingency_date', label: 'Home Inspection Contingency Date' },
    { value: 'home_inspection_contingency_time', label: 'Home Inspection Contingency Time' },
    { value: 'reinspection_date', label: 'Re-inspection Date' },
    { value: 'reinspection_time', label: 'Re-inspection Time' },
    { value: 'mortgage_commitment_date', label: 'Mortgage Commitment Date' },
    { value: 'other_reimbursements', label: 'Other Reimbursements' },
    { value: 'property_address', label: 'Property Address' },
    { value: 'radon_inspection_results', label: 'Radon Inspection Results' },
    { value: 'walk_through_date', label: 'Walk Through Date' },
    { value: 'property_built_year', label: 'Year Property Built' },
  ]
  const requirementsMet =
    (disabled == undefined || !disabled) &&
    filesUploaded &&
    notApplicableFiles?.length == 0 &&
    declinedFiles?.length == 0 &&
    missingFields.length == 0 &&
    missingRequiredEmailTemplateFiles.length == 0 &&
    missingRoles.length == 0 &&
    missingSteps.length == 0

  useDebounce(
    () => {
      if (initialRender) {
        setInitialRender(false)
      } else if ((Boolean(step.completed_at) || false) != completed) {
        setLoading(true)
        axios
          .patch(route('steps.update', step.id), { ...data, _method: 'patch' })
          .then((_) => router.reload({ only: ['contact', 'history', 'transaction', 'flash'] }))
          .catch((_) => setCompleted(!completed))
          .finally((_) => setLoading(false))
      }
    },
    300,
    [data],
  )

  useEffect(() => {
    setCompleted(Boolean(step.completed_at))
    checkRequiredFilesAreUploaded()
  }, [step])

  function checkRequiredFilesAreUploaded() {
    let uploaded = true

    if (!step.actionable || step.actionable_type !== 'File') {
      setFilesUploaded(true)
      return
    }

    let fileType = step.actionable

    if ((fileType.required_per_associate && associates.length > 1) || (fileType.required_per_state && transaction.states.length > 1)) {
      if (fileType.required_per_associate && associates.length > 1) {
        uploaded = fileType.required_per_state
          ? !associates.find((associate) => getStatesMissingContactFiles(associate).length > 0)
          : !associates.find(
              (associate) =>
                !transaction.allFiles?.find((file) => file.type === fileType.name && file.transaction_contact_id == associate.id),
            )
      } else if (fileType.required_per_state && transaction.states.length > 1) {
        uploaded = !transaction.states.find(
          (state) => !transaction.allFiles?.find((file) => file.type === fileType.name && file.state == state.state),
        )
      }
    } else {
      uploaded = transaction.allFiles?.find((file) => file.type === fileType.name)
    }

    setFilesUploaded(uploaded)
  }

  function getMissingFields() {
    if (!step.completed_at && step.settings?.fields?.length > 0) {
      return step.settings.fields.filter((field) => {
        return (
          transaction[field] == undefined ||
          (isNaN(transaction[field]) && transaction[field] == '') ||
          (field === 'property_address' &&
            (!Boolean(transaction.property_type_id) ||
              transaction.property_street === '' ||
              transaction.property_city === '' ||
              transaction.property_state === '' ||
              transaction.property_postal_code === ''))
        )
      })
    } else if (step.actionable_type === 'Role') {
      return missingContactFields(role.value)
    }

    return []
  }

  function getStatesMissingContactFiles(transactionContact) {
    return transaction.states.filter(
      (state) =>
        transactionContact.contact.associate.licenses.find((license) => !license.expired && license.state == state.state) &&
        !transaction.allFiles
          .filter((file) => file.type === step.actionable.name)
          .find((file) => file.transaction_contact_id == transactionContact.id && file.state == state.state),
    )
  }

  function getMissingRequiredEmailTemplateFiles() {
    if (!step.completed_at && step.actionable_type === 'Email' && step.actionable.files.length > 0) {
      return step.actionable.files.filter((file) => !transaction.allFiles.find((availableFile) => availableFile.type === file.name))
    }

    return []
  }

  function getMissingRoles() {
    if (!completed && Array.isArray(step.settings?.required_roles) && step.settings.required_roles.length > 0) {
      return step.settings.required_roles.filter((role) => {
        let key = role.party_representing ? `${role.party_representing}-${role.role}` : role.role
        return !isRoleInactive(key) && (!isRoleAvailable(key) || missingContactFields(key).length > 0)
      })
    }

    return []
  }

  function getMissingSteps() {
    return step.required_steps?.filter((rs) => !Boolean(rs.completed_at)) || []
  }

  function missingContactFields(role) {
    let list = role.split('-')
    let roleContact = transaction.contacts.find((contact) =>
      contact.roles.find(
        (r) => r.primary && (list.length == 2 ? r.party_representing == list[0] && r.name === list[1] : r.name === list[0]),
      ),
    )
    if (!roleContact) return false

    let missingFields = []
    let isBuyerOrSeller = ['Buyer', 'Seller'].indexOf(role) >= 0

    let isCooperatingPartyRole = role === transaction.type
    let isCooperatingPartyAgentNotApplicable = Boolean(
      transaction.inactive_roles.find(
        (role) => role.party_representing === (transaction.type === 'Buyer' ? 'Seller' : 'Buyer') && role.name === 'Agent',
      ),
    )

    if ([undefined, null, ''].indexOf(roleContact.contact?.first_name || roleContact.first_name) >= 0) missingFields.push('First Name')
    if ([undefined, null, ''].indexOf(roleContact.contact?.last_name || roleContact.last_name) >= 0) missingFields.push('Last Name')

    if (!isBuyerOrSeller && [undefined, null, ''].indexOf(roleContact.contact?.company || roleContact.company) >= 0)
      missingFields.push('Company')
    if (
      (!isBuyerOrSeller || isCooperatingPartyRole || isCooperatingPartyAgentNotApplicable) &&
      [undefined, null, ''].indexOf(roleContact.contact?.phone || roleContact.phone) >= 0
    )
      missingFields.push('Phone')
    if (
      (!isBuyerOrSeller || isCooperatingPartyRole || isCooperatingPartyAgentNotApplicable) &&
      [undefined, null, ''].indexOf(roleContact.contact?.email || roleContact.email) >= 0
    )
      missingFields.push('Email')

    let address = roleContact.contact && roleContact.contact.addresses?.length > 0 ? roleContact.contact.addresses[0] : roleContact

    if (
      (!isBuyerOrSeller || isCooperatingPartyRole || isCooperatingPartyAgentNotApplicable) &&
      !role.includes('Lender') &&
      ([undefined, null, ''].indexOf(address.street) >= 0 ||
        [undefined, null, ''].indexOf(address.city) >= 0 ||
        [undefined, null, ''].indexOf(address.state) >= 0 ||
        [undefined, null, ''].indexOf(address.postal_code) >= 0)
    )
      missingFields.push('Address')

    return missingFields
  }

  const onCheckChanged = (checked, notApplicable) => {
    setCompleted(Boolean(checked || notApplicable))
    setData({
      completed: checked,
      not_applicable: notApplicable,
    })
  }

  function isRoleInactive(role) {
    let values = role.split('-')
    role = values.length == 2 ? values[0] + ' ' + values[1] : transaction.type + ' ' + values[0]
    return Boolean(transaction.inactive_roles?.find((inactiveRole) => role === inactiveRole.party_representing + ' ' + inactiveRole.name))
  }

  function isRoleAvailable(role) {
    let values = role.split('-')
    role = values.length == 2 ? values[0] + ' ' + values[1] : values[0]

    return Boolean(
      transaction.available_roles?.find((availableRole) =>
        values.length == 2 ? role === availableRole.party_representing + ' ' + availableRole.name : role === availableRole.name,
      ),
    )
  }

  function isRoleRequired(key) {
    let role = roles.find((role) => role.value === key)
    return !role.optional || (!isRoleInactive(key) && !isRoleAvailable(key))
  }

  return (
    <ContactConsumer>
      {({ onEditing, onSearching }) => (
        <div
          className={classNames('relative rounded-md border transition-all', {
            'h-full duration-700': completedShown || !completed,
            'border-gray-400/60': (completedShown && !step.parent_id) || !completed,
            'h-0 border-0 opacity-0 duration-300': !completedShown && completed && !step.parent_id,
            'border-gray-200 bg-gray-300/60 text-gray-500': completed && completedShown && !step.parent_id,
            'border-gray-400/50 bg-gray-500/15 text-gray-500': (completed || completedShown) && step.parent_id,
            'bg-white shadow-md': !completed,
            'mb-1.5': !last && ((completedShown && completed) || (completed && step.parent_id)),
            'mb-6': !completed && !last,
            'mb-2': completedShown && !last,
          })}
        >
          <div
            className={classNames('rounded-md p-4 sm:px-6', {
              'bg-blue-200/25': !completed && !step.parent_id,
              'bg-white':
                !completed &&
                (step.controlled || step.actionable_type === 'Role' || (!completed && !(requirementsMet && !incompleteSubtasks))),
            })}
          >
            <div className="flex items-start">
              {editable && (
                <div className={classNames('mr-3 flex flex-shrink-0 sm:items-center')}>
                  <Checkbox
                    name={`completed_${index}`}
                    value={completed}
                    onChange={(checked) => onCheckChanged(checked, false)}
                    disabled={step.controlled || (!completed && !(requirementsMet && !incompleteSubtasks))}
                  />
                </div>
              )}

              {Boolean(step.not_applicable) && <span className="pr-3 text-xl font-bold text-gray-400">NA</span>}

              <div className="flex-1 space-y-1.5">
                <div className="mt-1 flex flex-1 gap-1.5 sm:mt-0.5">
                  {step.index > 0 && (
                    <span
                      className={classNames(
                        'flex-shrink-0 whitespace-nowrap text-lg font-semibold leading-normal sm:leading-tight',
                        completed ? 'text-gray-600' : 'text-gray-900',
                      )}
                    >
                      {step.index}.
                    </span>
                  )}

                  <div className="flex-1">
                    <div
                      className={classNames('text-lg leading-normal sm:leading-tight', completed ? 'text-gray-600' : 'text-black', {
                        'line-through': step.not_applicable,
                      })}
                      dangerouslySetInnerHTML={{ __html: step.name }}
                    ></div>
                  </div>
                </div>

                {!completed &&
                  (!requirementsMet ||
                    Boolean(incompleteSubtasks) ||
                    (transaction.propertyTypes?.length > 1 && step.property_types?.length > 0) ||
                    (transaction.states?.length > 1 && step.states?.length > 0)) && (
                    <div className="flex flex-wrap items-center gap-1.5">
                      {transaction.propertyTypes?.length > 1 &&
                        step.propertyTypes
                          ?.filter((type) => transaction.propertyTypes.some((t) => t.name == type.name))
                          .map((tag) => <Tag tag={tag} key={tag.id} />)}

                      {transaction.states?.length > 1 &&
                        step.states
                          ?.filter((state) => transaction.states.some((s) => s.state == state.name))
                          .map((tag) => (
                            <Tag tag={{ ...tag, type: 'state', color: states.find((s) => s.value == tag.name)?.color }} key={tag.name} />
                          ))}

                      {missingSteps.length > 0 && (
                        <div className="inline-block rounded bg-red-100 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-red-600">
                          {step.required_steps
                            .sort((a, b) => (a.index > b.index ? 1 : -1))
                            .map(
                              (s, index) =>
                                (index > 0
                                  ? step.required_steps.length > 2 && index + 1 < step.required_steps.length
                                    ? ', '
                                    : ' & '
                                  : '') + s.nameWithoutTags,
                            )}{' '}
                          Step{step.required_steps?.length > 1 && 's'} required to complete
                        </div>
                      )}

                      {step.actionable_type === 'Role' && step.actionable && isRoleRequired(step.actionable.key) && (
                        <div className="inline-block rounded bg-red-100 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-red-600">
                          {step.actionable.name} role required {step.actionable.optional && 'or marked N/A'} to complete
                        </div>
                      )}

                      {missingRoles.length > 0 && (
                        <div className="inline-block rounded bg-red-100 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-red-600">
                          {missingRoles.map((role, index) => {
                            let output = index > 0 ? (missingRoles.length > 2 && index + 1 < missingRoles.length ? ', ' : ' & ') : ''

                            output += role.party_representing ? `${role.party_representing} ` : ''
                            output += role.role
                            if (role.role === 'Agent') {
                              output += ` or ${role.party_representing}`
                            }

                            let roleContact = transaction.contacts.find((contact) =>
                              contact.roles.find(
                                (r) =>
                                  r.primary &&
                                  (!role.party_representing || role.party_representing === r.party_representing) &&
                                  r.name === role.role,
                              ),
                            )

                            let key = role.party_representing ? `${role.party_representing}-${role.role}` : role.role
                            if (roleContact && missingContactFields(key).length > 0) {
                              let fields = missingContactFields(key)
                              output += ' ('
                              output += fields.reduce(
                                (output, field, index) =>
                                  (output += (index > 0 ? (fields.length > 2 && index + 1 < fields.length ? ', ' : ' & ') : '') + field),
                                '',
                              )
                              output += ' missing)'
                            }

                            return output
                          })}{' '}
                          required to complete
                        </div>
                      )}

                      {missingFields.length > 0 && (
                        <div className="inline-block rounded bg-red-100 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-red-600">
                          {missingFields.map(
                            (field, index) =>
                              (index > 0 ? (missingFields.length > 2 && index + 1 < missingFields.length ? ', ' : ' & ') : '') +
                              (step.actionable_type === 'Role'
                                ? ['First Name', 'Last Name', 'Company', 'Phone', 'Email', 'Address'].find((f) => f === field)
                                : fields.find((f) => f.value === field)?.label),
                          )}{' '}
                          data required
                        </div>
                      )}

                      {missingRequiredEmailTemplateFiles.length > 0 && (
                        <div className="inline-block rounded bg-red-100 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-red-600">
                          {missingRequiredEmailTemplateFiles.map(
                            (file, index) =>
                              (index > 0
                                ? missingRequiredEmailTemplateFiles.length > 2 && index + 1 < missingRequiredEmailTemplateFiles.length
                                  ? ', '
                                  : ' & '
                                : '') + file.name,
                          )}{' '}
                          file{missingRequiredEmailTemplateFiles.length > 1 ? 's' : ''} required
                        </div>
                      )}

                      {notApplicableFiles?.map((file, index) => (
                        <div
                          className="inline-flex items-center gap-2 rounded bg-red-100 py-1 pl-2.5 pr-1.5 text-sm font-medium uppercase leading-none text-red-600"
                          key={index}
                        >
                          <span>{file.filename} File</span>
                          <span className="rounded bg-red-500 px-1.5 py-1 font-bold leading-none text-white">
                            <i className="fas fa-file-slash mr-1.5"></i>
                            <span>Not Applicable</span>
                          </span>
                        </div>
                      ))}

                      {declinedFiles?.map((file, index) => (
                        <div
                          className="inline-flex items-center gap-2 rounded bg-red-100 py-1 pl-2.5 pr-1.5 text-sm font-medium uppercase leading-none text-red-600"
                          key={index}
                        >
                          <span>{file.filename} File</span>
                          <span className="rounded bg-red-500 px-1.5 py-1 font-bold leading-none text-white">
                            <i className="fas fa-file-slash mr-1.5"></i>
                            <span>Declined</span>
                          </span>
                        </div>
                      ))}

                      {incompleteSubtasks > 0 && (
                        <div className="inline-block rounded bg-red-100 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-red-600">
                          {incompleteSubtasks} Subtask{incompleteSubtasks == 1 ? '' : 's'} remaining to complete
                        </div>
                      )}

                      {step.actionable_type === 'File' &&
                        step.actionable &&
                        !filesUploaded &&
                        ((step.actionable.required_per_associate && associates.length > 1) ||
                        (step.actionable.required_per_state && transaction.states.length > 1) ? (
                          step.actionable.required_per_state ? (
                            step.actionable.required_per_associate ? (
                              associates.map((associate) =>
                                getStatesMissingContactFiles(associate).map((state) => (
                                  <RequiredFile
                                    name={step.actionable.name}
                                    associate={
                                      step.actionable.required_per_associate && (associate.contact.full_name || associate.full_name)
                                    }
                                    state={state.state}
                                    key={`${state.id}-${associate.id}`}
                                  />
                                )),
                              )
                            ) : (
                              transaction.states
                                .filter(
                                  (state) =>
                                    !transaction.allFiles?.find((file) => file.type === step.actionable.name && file.state == state.state),
                                )
                                .map((state) => <RequiredFile name={step.actionable.name} state={state.state} key={state.id} />)
                            )
                          ) : (
                            associates
                              .filter(
                                (associate) =>
                                  !transaction.allFiles?.find(
                                    (file) => file.type === step.actionable.name && file.transaction_contact_id == associate.id,
                                  ),
                              )
                              .map((associate) => (
                                <RequiredFile
                                  name={step.actionable.name}
                                  associate={associate.contact.full_name || associate.full_name}
                                  key={associate.id}
                                />
                              ))
                          )
                        ) : (
                          <div className="inline-block rounded bg-red-100 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-red-600">
                            {step.actionable.name} file required to complete
                          </div>
                        ))}
                    </div>
                  )}

                {!completed && step.controlled && (
                  <div className="inline-block rounded bg-red-100 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-red-600">
                    Action required to complete
                  </div>
                )}
              </div>
            </div>
          </div>

          {(!completed || completedShown) &&
            (['<p></p>', ''].indexOf(step.content) < 0 ||
              step.subtasks?.length > 0 ||
              step.settings?.fields?.length > 0 ||
              (editable && ['Role', 'Email', 'Form'].indexOf(step.actionable_type) >= 0) ||
              (editable && step.actionable_type === 'File' && step.actionable)) && (
              <Fragment>
                <div
                  className={classNames('space-y-4 border-t border-gray-400/60', {
                    'bg-gray-100': !completed && step.settings?.fields?.length > 0,
                    'p-4 sm:p-6': !completed || step.subtasks?.length > 0,
                  })}
                >
                  {['<p></p>', ''].indexOf(step.content) < 0 && (
                    <div
                      className={classNames('ProseMirror', {
                        'p-4 sm:p-6': completed && (step.parent_id || step.subtasks?.length == 0),
                      })}
                      dangerouslySetInnerHTML={{ __html: step.content }}
                    ></div>
                  )}

                  {step.subtasks?.length > 0 && (
                    <div>
                      {step.subtasks
                        .filter((subtask) => completedShown || editable || !subtask.completed_at)
                        .map((subtask, i) => (
                          <Step
                            associates={associates}
                            completedShown={completedShown}
                            disabled={!requirementsMet}
                            index={index}
                            editable={editable}
                            last={i + 1 == step.subtasks.length}
                            step={subtask}
                            transaction={transaction}
                            key={subtask.id}
                            onEmailOpening={(subtask) => onEmailOpening(subtask)}
                            onFormOpening={(subtask) => onFormOpening(subtask)}
                          />
                        ))}
                    </div>
                  )}

                  {!completed && editable && step.actionable_type === 'Data' && <DynamicForm step={step} transaction={transaction} />}

                  {!completed && editable && step.actionable_type === 'Email' && (
                    <div className="flex justify-center">
                      <Button
                        theme="solid"
                        disabled={!requirementsMet || associates.find((tc) => tc.is_me) == undefined}
                        onClick={() => onEmailOpening(step)}
                      >
                        Review & Send Email
                      </Button>
                    </div>
                  )}

                  {!completed && editable && step.actionable_type === 'File' && step.actionable && (
                    <FileAction associates={associates} step={step} transaction={transaction} />
                  )}

                  {!completed && editable && step.actionable_type === 'Form' && (
                    <div className="flex justify-center">
                      <Button theme="solid" disabled={!requirementsMet} onClick={() => onFormOpening(step)}>
                        Open {step.actionable?.name}
                      </Button>
                    </div>
                  )}

                  {!completed && editable && step.actionable_type === 'Role' && Boolean(role) && (
                    <RoleAction
                      contact={transaction.contacts.find((contact) =>
                        contact.roles.find((r) => {
                          let list = role.value.split('-')
                          return (
                            r.primary && (list.length == 2 ? r.party_representing == list[0] && r.name === list[1] : r.name === list[0])
                          )
                        }),
                      )}
                      disabled={!(missingRoles.length == 0 && missingSteps.length == 0)}
                      inactive={isRoleInactive(role.value)}
                      role={role}
                      missing={missingContactFields(role.value)}
                      onEditing={onEditing}
                      onSearching={onSearching}
                    />
                  )}
                </div>
              </Fragment>
            )}

          {!completed && editable && (step.optional || (step.actionable_type === 'Role' && !isRoleRequired(step.actionable.key))) && (
            <div className="flex items-center justify-between border-t border-gray-400/60 p-4">
              <button role="button" className="text-gray-500 underline" onClick={() => onCheckChanged(true, true)}>
                Not Applicable (NA)
              </button>
            </div>
          )}
        </div>
      )}
    </ContactConsumer>
  )
}

export default Step
