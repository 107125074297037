import Button from '@/Shared/Button'
import classNames from 'classnames'

const Actions = () => null

const Alert = ({ children, dismissable, heading, subtext, type, ...props }) => {
  const actions = Array.isArray(children) ? children.find((el) => el?.type === Actions) : children?.type === Actions ? children : null

  return (
    <div
      className={classNames(
        'flex rounded-md border-2 border-opacity-75 font-medium',
        {
          'items-start': props.align == 'top',
          'items-center': props.align == 'center',
          'items-end': props.align == 'bottom',
          'gap-4 p-4': !props.size,
          'gap-3 py-1.5 pl-3 pr-4': props.size === 'small',
          'border-orange-300 bg-orange-100': type === 'warning',
          'border-gray-200 bg-blue-100': type === 'info',
          'border-red-400 bg-red-100': type === 'danger',
          'border-orange-300 bg-yellow-100': type === 'notice',
        },
        props.className,
      )}
    >
      <i
        className={classNames('text-2xl', {
          'fas fa-triangle-exclamation text-orange-600': type === 'warning',
          'far fa-info-circle text-blue-500': type === 'info',
          'fa-solid fa-circle-exclamation text-red-500': type === 'danger',
          'far fa-info-circle text-orange-500': type === 'notice',
        })}
      ></i>

      <div className="flex-1">
        {heading &&
          (typeof heading === 'object' ? heading : <p className="font-bold leading-snug" dangerouslySetInnerHTML={{ __html: heading }} />)}
        {subtext &&
          (typeof subtext === 'object' ? (
            subtext
          ) : (
            <p className="font-normal leading-snug" dangerouslySetInnerHTML={{ __html: subtext }} />
          ))}
      </div>

      {actions && (
        <div className={`flex shrink-0 ${props?.alignment || 'items-center'} justify-end self-stretch sm:w-auto md:pl-4`}>
          {actions.props.children}

          {dismissable && (
            <Button theme="clean-icon">
              <i className="far fa-times text-lg text-gray-400"></i>
            </Button>
          )}
        </div>
      )}
    </div>
  )
}

Alert.defaultProps = {
  align: 'center',
  type: 'info',
  dismissable: false,
}

Alert.Actions = Actions

export default Alert
