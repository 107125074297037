import { Fragment, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import useDebounce from '@/hooks/useDebounce'
import classNames from 'classnames'
import Button from '@/Shared/Button'
import Contact from './Item'
import Dialog from '@/Shared/Dialog/Index'
import Link from '@/Shared/Link'
import TextInput from '@/Shared/Forms/TextInput'
import { router } from '@inertiajs/react'
import { usePage } from '@inertiajs/react'

export default ({ settings, open, transaction, onCreate, onClose }) => {
  const { roles: originalRoles } = usePage().props
  let focusRef = useRef()

  const minCharactersToSearch = 3
  const [fetching, setFetching] = useState(false)
  const [existing, setExisting] = useState([])
  const [controllers, setControllers] = useState(null)
  const [pros, setPROs] = useState([])
  const [contacts, setContacts] = useState([])
  const [search, setSearch] = useState('')
  const [activeContactId, setActiveContactId] = useState(null)
  const [errors, setErrors] = useState(null)
  const currentRole = settings?.roles && originalRoles.find((r) => r.value === settings.roles[0])
  const canAssignToExistingContacts = currentRole && ['Buyer', 'Seller'].indexOf(currentRole.value) == -1
  const escrowAgentIsAssigned = transaction.contacts.some((contact) => contact.roles.some((role) => role.name === 'Escrow Agent'))
  const hasResults = existing.length > 0 || controllers?.length > 0 || contacts.length > 0 || pros.length > 0

  useDebounce(
    () => {
      if (search.length >= minCharactersToSearch) {
        filterData()
      } else if (search.length == 0 && currentRole) {
        loadCurrentContacts()
      } else {
        setExisting([])
        setControllers(null)
        setContacts([])
        setPROs([])
        setFetching(false)
      }
    },
    300,
    [search],
  )

  useEffect(() => {
    if (open) {
      if (canAssignToExistingContacts) {
        loadCurrentContacts()

        if (currentRole?.value === 'Escrow Agent') {
          axios
            .get('/api/contacts', {
              params: {
                limit: 25,
                search: 'Better Living Real Estate',
                transaction_id: transaction.id,
                groups: 'Controller',
                role: currentRole.value,
              },
            })
            .then((response) => {
              setControllers(response.data.filter((contact) => contact.type === 'Controller'))
            })
        }
      } else {
        setExisting([])
        setControllers(null)
        setContacts([])
        setPROs([])
      }
    }
  }, [open])

  const filterData = () => {
    axios
      .get('/api/contacts', {
        params: {
          limit: 25,
          search: search,
          transaction_id: transaction.id,
          groups: 'all',
          role: currentRole?.value,
        },
      })
      .then((response) => {
        let contacts = response.data

        let existingContacts = contacts.filter((contact) => {
          let contact_id = contact.contact?.id || contact.id
          return (
            (contact.type === 'TransactionContact' && !contact.contact_id) ||
            transaction.contacts.find((tc) => {
              return tc.id == contact_id || Boolean(tc.followers.length > 0 && tc.followers.find((follower) => follower.id == contact_id))
            })
          )
        })

        if (canAssignToExistingContacts) {
          setExisting(existingContacts.filter((contact) => !['Associate', 'Controller'].some((type) => type === contact.type)))
        }

        contacts = contacts.filter((contact) => !existingContacts.find((c) => c.id == contact.id))

        let eligibleGroups = ['PRO']
        if (currentRole == undefined || ['Buyer', 'Buyer-Agent', 'Seller', 'Seller-Agent'].some((role) => role === currentRole?.value)) {
          eligibleGroups = eligibleGroups.concat('Associate')
        }

        if ((currentRole == undefined && !escrowAgentIsAssigned) || currentRole?.value === 'Escrow Agent') {
          setControllers(contacts.filter((contact) => contact.type === 'Controller'))
        }

        setPROs(contacts.filter((contact) => eligibleGroups.indexOf(contact.type) >= 0))
        setContacts(contacts.filter((contact) => contact.type === 'Contact' && !contact.followed))

        setFetching(false)
      })
  }

  const loadCurrentContacts = () => {
    setExisting(
      transaction.contacts_added.filter(
        (contact) =>
          contact.type === 'PRO' ||
          transaction.contacts.find((tc) => contact.id == tc.id && !tc.roles.find((role) => ['Buyer', 'Seller'].indexOf(role.name) >= 0)),
      ),
    )
    setPROs([])
    setContacts([])
    setFetching(false)
  }

  const refreshResults = () => {
    router.visit(window.location.href, {
      preserveScroll: true,
      preserveState: true,
      replace: true,
      onFinish: (_visit) => {
        setErrors(null)
      },
    })
  }

  const onClosing = () => {
    onClose()

    setTimeout(() => {
      setSearch('')
    }, 300)
  }

  return (
    <Dialog
      focusRef={focusRef}
      background="bg-gray-100"
      cancelText="Close"
      position="top"
      show={open}
      size="sm:max-w-4xl"
      title="Search Contacts & PROs"
      onClosed={() => onClosing()}
      hideFooter
    >
      <div className="flex flex-col">
        <div
          className={classNames('relative flex w-full flex-grow', {
            'mb-6 mt-2': canAssignToExistingContacts,
          })}
        >
          <TextInput
            ref={focusRef}
            classes="flex-grow"
            inputClasses="px-4 pr-14 py-2 bg-gray-50"
            placeholder="Search by name, phone number or email address"
            value={search}
            onChange={(value) => {
              if (value.length >= minCharactersToSearch) {
                setFetching(true)
              }
              setSearch(value)
            }}
          />

          <span className="absolute inset-y-0 right-0 flex items-center justify-center">
            {search.length > 0 && search.length < 3 && (
              <span className="text-base text-gray-500">Type {3 - search.length} more characters</span>
            )}
            <span className="flex h-full items-center justify-center px-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                  <path
                    className="fill-current text-primary-500"
                    d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                    id="Path-2"
                    fillRule="nonzero"
                    opacity="0.3"
                  ></path>
                  <path
                    className="fill-current text-primary-500"
                    d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                    id="Path"
                    fillRule="nonzero"
                  ></path>
                </g>
              </svg>
            </span>
          </span>
        </div>

        {!fetching && !hasResults && search.length < minCharactersToSearch ? (
          <div className={classNames('space-y-3', canAssignToExistingContacts ? 'order-1 text-gray-700' : 'p-8 text-center text-gray-900')}>
            <p>
              To add a contact to this transaction
              {currentRole?.label ? (
                <Fragment>
                  {' '}
                  for the <span className="font-bold">{currentRole.label}</span> role
                </Fragment>
              ) : (
                ''
              )}
              , search your existing contacts and our directory of PROs.
            </p>

            {!canAssignToExistingContacts && (
              <p>
                <i className="far fa-info-circle pr-1 text-blue-500"></i> If no match is found, you will be provided an option to create a
                new contact.
              </p>
            )}
          </div>
        ) : (
          <Fragment>
            {fetching ? (
              <div className="my-10 flex items-center justify-center">
                <div role="status" className="flex items-center gap-3">
                  <svg
                    aria-hidden="true"
                    className="h-8 w-8 animate-spin fill-blue-500 text-gray-300"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span>Searching Contacts & PROs...</span>
                </div>
              </div>
            ) : (
              <div className="order-3 space-y-6">
                {hasResults && (
                  <Fragment>
                    {errors?.contactExists && (
                      <div className="mb-2 rounded-md border border-red-200 bg-red-100 p-4 text-center font-semibold text-red-600">
                        <p className="mb-2">{errors.contactExists}</p>

                        <Button theme="outline" onClick={() => refreshResults()}>
                          Refresh Search Results
                        </Button>
                      </div>
                    )}

                    {existing?.length > 0 && (
                      <div>
                        <h3 className="mb-2 text-lg font-bold">Assign the {currentRole?.label} role to an existing contact</h3>

                        {search.length == 0 && canAssignToExistingContacts && (
                          <p className="text-gray-700">
                            You may instead choose to assign the {currentRole?.label} role to a PRO already added to this transaction using
                            the <strong>Assign {currentRole?.label} Role</strong> button below.
                          </p>
                        )}

                        <div className="mt-4 flex flex-col">
                          {existing.map((contact, index) => (
                            <Contact
                              activeContactId={activeContactId}
                              contact={contact}
                              settings={settings}
                              position={existing.length - index}
                              last={index == 0}
                              transaction={transaction}
                              key={contact.id}
                              onAdding={(id) => setActiveContactId(id)}
                              onClose={onClosing}
                              onError={(errors) => setErrors(errors)}
                            />
                          ))}
                        </div>
                      </div>
                    )}

                    {controllers?.length > 0 &&
                      ((currentRole == undefined && !escrowAgentIsAssigned) || currentRole?.value === 'Escrow Agent') && (
                        <div>
                          <div className={classNames('mb-4', !canAssignToExistingContacts ? 'mt-6' : '')}>
                            <h3 className="text-lg font-bold">
                              {search.length === 0 ? 'Assign the Escrow Agent role to the ' : ''}Better Living Real Estate® Controller
                            </h3>

                            <p className="text-gray-700">
                              Use the following when assigning Better Living Real Estate® as the Escrow Agent.
                            </p>
                          </div>

                          {controllers.map((contact) => (
                            <Contact
                              activeContactId={activeContactId}
                              contact={contact}
                              settings={settings}
                              position={0}
                              last={true}
                              transaction={transaction}
                              key={contact.id}
                              onAdding={(id) => setActiveContactId(id)}
                              onClose={onClosing}
                              onError={(errors) => setErrors(errors)}
                            />
                          ))}
                        </div>
                      )}

                    {search.length > 0 && (
                      <Fragment>
                        {pros?.length > 0 && (
                          <div className="mt-4">
                            {search.length > 0 && (
                              <h3 className="mb-2 block text-lg font-medium">
                                Maintained by the PRO <span className="text-red-500">- Recommended</span>
                              </h3>
                            )}

                            <div className="flex flex-col">
                              {pros.map((contact, index) => (
                                <Contact
                                  activeContactId={activeContactId}
                                  contact={contact}
                                  settings={settings}
                                  position={pros.length - index}
                                  last={index == 0}
                                  transaction={transaction}
                                  key={contact.id}
                                  onAdding={(id) => setActiveContactId(id)}
                                  onClose={onClosing}
                                  onError={(errors) => setErrors(errors)}
                                />
                              ))}
                            </div>
                          </div>
                        )}
                        {contacts?.length > 0 && (
                          <div>
                            {search.length > 0 && (
                              <Fragment>
                                <h3 className="mb-2 mt-4 block text-lg font-medium">Others that we found in your Contacts</h3>

                                <div className="mb-3">
                                  Duplicate contacts?{' '}
                                  <Link href={route('contacts.merge.index')} inertia>
                                    Review &amp; merge your duplicates
                                  </Link>{' '}
                                  to make sure only one copy of each contact exists.
                                </div>
                              </Fragment>
                            )}

                            {search.length == 0 && contacts.length > 0 && (
                              <h3 className="mb-2 mt-4 block text-lg font-medium">Current Contacts</h3>
                            )}

                            <div className="flex flex-col">
                              {contacts.map((contact, index) => (
                                <Contact
                                  activeContactId={activeContactId}
                                  contact={contact}
                                  settings={settings}
                                  position={contacts.length - index}
                                  last={index == 0}
                                  transaction={transaction}
                                  key={contact.id}
                                  onAdding={(id) => setActiveContactId(id)}
                                  onClose={onClosing}
                                  onError={(errors) => setErrors(errors)}
                                />
                              ))}
                            </div>
                          </div>
                        )}
                      </Fragment>
                    )}
                  </Fragment>
                )}

                {search.length >= minCharactersToSearch && (
                  <div className="mt-8 rounded-lg border-2 border-gray-300 bg-white p-6 text-center font-semibold">
                    {existing?.length == 0 && pros?.length == 0 && contacts?.length == 0
                      ? `No results found for "${search}"`
                      : "Can't find the person you're looking for?"}

                    <div className="mt-2">
                      <Button theme="outline" onClick={() => onCreate(search)}>
                        Create a new contact
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </Fragment>
        )}
      </div>
    </Dialog>
  )
}
