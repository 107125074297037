import { useEffect, useRef, useState } from 'react'
import Avatar from '@/Shared/Avatar'
import Button from '@/Shared/Button'
import Checkbox from '@/Shared/Forms/Checkbox'
import Dialog from '@/Shared/Dialog/Index'
import Notice from '@/Shared/Notice'
import { useForm } from '@inertiajs/react'

export default ({ contact, open, onClose }) => {
  let focusRef = useRef()

  const BROKERAGE_ADDRESS_LABEL = 'Brokerage Business & Accounting Address'
  const form = useForm()
  const { data, setData, post } = form
  const [similarContacts, setSimilarContacts] = useState([])
  const [selected, setSelected] = useState(null)

  useEffect(() => {
    if (contact) {
      setData({
        ...data,
        id: null,
        follow: contact.contact_type !== 'Contact' || null,
        followable_id: contact.id,
        followable_type: contact.type,
        first_name: contact.first_name,
        nickname: contact.nickname,
        middle_name: contact.middle_name,
        last_name: contact.last_name,
        suffix: contact.suffix,
        company: contact.company,
        title: contact.title,
        industry: contact.industry,
        phone_numbers: [
          contact.phone_numbers?.length > 0
            ? contact.phone_numbers[0]
            : {
                phone: contact.phone,
                phone_formatted: contact.phone_formatted,
                type: contact.phone_type,
              },
        ],
        emails: [
          contact.emails?.length > 0
            ? contact.emails[0]
            : {
                email: contact.email,
                type: 'Work',
              },
        ],
        addresses: [
          contact.addresses?.length > 0
            ? contact.addresses[0]
            : {
                address_inline: contact.address_inline,
                street: contact.street,
                street2: contact.street2,
                city: contact.city,
                state: contact.state,
                postal_code: contact.postal_code,
                type: contact.associate ? BROKERAGE_ADDRESS_LABEL : 'Work',
              },
        ],
      })
    }
  }, [contact])

  useEffect(() => {
    if (data?.followable_id) {
      findSimilarContacts()
    }
  }, [data?.followable_id])

  useEffect(() => setData({ ...data, id: Boolean(selected) ? selected : null }), [selected])

  const findSimilarContacts = () => {
    axios
      .get(route('contacts.similar'), {
        params: {
          first_name: data.first_name,
          last_name: data.last_name,
          phone: data.phone_numbers?.length > 0 ? data.phone_numbers[0].phone : '',
          email: data.emails?.length > 0 ? data.emails[0].email : '',
        },
      })
      .then((response) => {
        setSimilarContacts(response.data)
      })
  }

  const onAdd = () => {
    post(route('contacts.copy'), {
      onFinish: () => onClosing(),
    })
  }

  const onClosing = () => {
    onClose()
  }

  return (
    <Dialog
      focusRef={focusRef}
      footerActions={
        <Button theme="solid" disabled={similarContacts.length > 0 && selected == null} onClick={() => onAdd()}>
          <span className="text-lg">Add</span>
        </Button>
      }
      background="bg-gray-100"
      cancelText="Close"
      position="top"
      show={open}
      size="sm:max-w-2xl"
      title={contact ? `Add ${contact.full_name} to your Contacts` : ''}
      onClosed={() => onClosing()}
    >
      {contact && (
        <div className="space-y-4 px-4">
          <div className="mx-auto max-w-xl space-y-6">
            <div className="rounded-b-lg rounded-t-lg border border-gray-300 bg-white px-5 py-3 shadow">
              <div className="flex flex-grow flex-wrap items-center justify-center gap-5 text-left">
                <div className="space-y-1">
                  <Avatar contact={contact} height="h-20" width="w-20" />

                  {contact?.contact_type !== 'Contact' && (
                    <div className="flex items-center justify-center leading-none">
                      <div className="mt-0.5 inline-flex items-center whitespace-nowrap rounded-full bg-purple-100 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-purple-800">
                        <span>PRO</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className="flex-1 leading-snug text-gray-600 sm:mb-0">
                  <div className="font-semibold text-blue-500">{contact.full_name}</div>

                  {data.industry && <div className="font-medium">{data.industry}</div>}
                  {data.company && <div>{data.company}</div>}

                  {data.addresses?.length > 0 &&
                    data.addresses
                      .filter((address) => address.type !== BROKERAGE_ADDRESS_LABEL)
                      .map((address, index) => (
                        <div key={index}>
                          {address.address_inline}
                          {address.type && ` (${address.type})`}
                        </div>
                      ))}

                  {data.phone_numbers?.length > 0 &&
                    data.phone_numbers.map((phone, index) => (
                      <div key={index}>
                        {phone.phone_formatted}
                        {phone.type && ` (${phone.type})`}
                      </div>
                    ))}

                  {data.emails?.length > 0 &&
                    data.emails.map((email, index) => (
                      <div key={index}>
                        {email.email}
                        {email.type && ` (${email.type})`}
                      </div>
                    ))}
                </div>
              </div>
            </div>

            {contact?.contact_type !== 'Contact' && (
              <div className="flex justify-center">
                <Checkbox
                  label="Follow this PRO"
                  description="This contact is currently linked to a PRO. &nbsp;Following this PRO will notify you whenever they make changes to the contact information they share."
                  name="follow"
                  value={data.follow}
                  onChange={(checked) => setData({ ...data, follow: checked })}
                />
              </div>
            )}

            {similarContacts.length > 0 && (
              <div className="flex justify-end">
                <Button theme={selected == 0 ? 'solid' : 'border'} onClick={() => setSelected(0)}>
                  <div className="flex items-center">
                    {selected == 0 && <i className="fas fa-check pr-3"></i>}
                    {selected == 0 ? 'Selected' : 'Add as a NEW contact'}
                  </div>
                </Button>
              </div>
            )}
          </div>

          {similarContacts.length > 0 && (
            <div className="space-y-2">
              <Notice
                heading={
                  <div>
                    You already have <span className="text-lg text-orange-500">{similarContacts.length}</span> similar contact
                    {similarContacts.length > 1 ? 's' : ''}
                  </div>
                }
                subHeading={
                  <p>
                    To continue, you can choose to either add a <strong>NEW</strong> contact (above) or select{' '}
                    {similarContacts.length > 1 ? 'one of the following existing contacts' : 'the existing contact'} (below) to link to this
                    transaction.
                  </p>
                }
              />

              <dl className="overflow-hidden rounded-lg border border-gray-300 bg-white">
                {similarContacts.map((contact, index) => (
                  <div className="flex flex-grow flex-wrap items-center justify-center gap-4 px-5 py-3 text-left" key={contact.id}>
                    <div className="space-y-1">
                      <Avatar contact={contact} height="h-14" width="w-14" />

                      {contact.following?.length > 0 && (
                        <div className="flex items-center justify-center leading-none">
                          <div className="mt-0.5 inline-flex items-center whitespace-nowrap rounded-full bg-purple-100 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-purple-800">
                            <span>PRO</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="flex-1 truncate leading-snug text-gray-600 sm:mb-0">
                      <div className="font-semibold text-blue-500">{contact.full_name}</div>

                      {contact.industry && <div className="font-medium">{contact.industry}</div>}
                      {contact.company && <div>{contact.company}</div>}

                      <dd className="text-gray-700">
                        {contact.emails?.length > 0 &&
                          contact.emails
                            .filter((r) => r.primary)
                            .map((email, index) => (
                              <div className="truncate" key={index}>
                                {email.email}
                                {email.type && ` (${email.type})`}
                              </div>
                            ))}
                        {contact.phone_numbers?.length > 0 &&
                          contact.phone_numbers
                            .filter((r) => r.primary)
                            .map((phone, index) => (
                              <div key={index}>
                                {phone.phone_formatted}
                                {phone.type && ` (${phone.type})`}
                              </div>
                            ))}
                      </dd>
                    </div>
                    <div className="flex shrink-0 items-center justify-end">
                      <Button theme={selected == contact.id ? 'solid' : 'border'} onClick={() => setSelected(contact.id)}>
                        <div className="flex items-center">
                          {selected == contact.id && <i className="fas fa-check pr-3"></i>}
                          {selected == contact.id ? 'Selected' : 'Select'}
                        </div>
                      </Button>
                    </div>
                  </div>
                ))}
              </dl>
            </div>
          )}
        </div>
      )}
    </Dialog>
  )
}
