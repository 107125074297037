import { Fragment, useState } from 'react'
import Button from '@/Shared/Button'
import Edit from './Edit'
import Helpers from '@/utils/helpers'
import { usePage } from '@inertiajs/react'
import classNames from 'classnames'
import { Tooltip } from '../../../../Shared/Tooltip'

export default () => {
  const { associate } = usePage().props.contact
  const { user } = usePage().props.auth
  const [entry, setEntry] = useState(null)

  const viewTransaction = (e, entry) => {
    e.stopPropagation()

    window.open(route('transactions.show', entry.transaction_id), '_blank')
  }

  return (
    <div className="flex flex-col overflow-y-auto px-6">
      {associate.cap_history?.length > 0 ? (
        <table className="w-full border border-gray-300 bg-white">
          <thead>
            <tr className="bg-gray-200">
              <th width="145px" className="py-2 pl-4 text-left font-medium">
                Date
              </th>

              <th width="145px" className="py-2 pl-3 text-center font-medium">
                CAP Change
              </th>

              <th className="py-2 pl-3 text-left font-medium">Transaction / Comments</th>

              <th width="50px"></th>
            </tr>
          </thead>

          <tbody className="divide-y divide-gray-300">
            {associate.cap_history.map((entry, index) => (
              <tr
                className={classNames({ 'hover:cursor-pointer hover:bg-gray-100': Helpers.isAdminOrHR(user) })}
                key={index}
                onClick={() => Helpers.isAdminOrHR(user) && setEntry(entry)}
              >
                <td className="pl-4 pt-3 text-left align-top">{entry.date}</td>

                <td className="py-2 pl-3 text-center">
                  <span className={classNames('font-semibold leading-none', { 'text-red-600': associate.cap_history[index + 1] })}>
                    {Helpers.formatCurrency(
                      associate.cap_history[index + 1] ? entry.cap - associate.cap_history[index + 1].cap : entry.cap,
                      2,
                    )}
                  </span>
                  {associate.cap_history[index + 1] && (
                    <div className="text-sm leading-tight text-gray-600">{Helpers.formatCurrency(entry.cap)}</div>
                  )}
                </td>

                <td className="py-2 pl-3 text-left">
                  {entry.transaction_id ? (
                    <span className="font-bold">{entry.transaction}</span>
                  ) : (
                    entry.comments !== '' && (
                      <div className="flex items-center gap-1.5">
                        <i className="far fa-comment text-gray-400"></i> {entry.comments}
                      </div>
                    )
                  )}
                </td>

                <td className="cursor-pointer whitespace-nowrap px-6 py-3 text-right font-medium">
                  <div className="flex items-center justify-end gap-3">
                    {entry.transaction_id && (
                      <Tooltip label="Visit Page" placement="bottom">
                        <Button theme="icon" onClick={(e) => viewTransaction(e, entry)}>
                          <span className="sr-only">View Transaction</span>
                          <i className="far fa-external-link text-xl text-gray-500 group-hover:font-bold group-hover:text-primary-500"></i>
                        </Button>
                      </Tooltip>
                    )}

                    {Helpers.isAdminOrHR(user) && <i className="far fa-angle-right text-2xl leading-none text-gray-800"></i>}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="p-4 text-center text-gray-700">No Cap History has been added.</div>
      )}

      <Edit entry={entry} open={entry != undefined} onClosed={() => setEntry(null)} />
    </div>
  )
}
