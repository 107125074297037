import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { router } from '@inertiajs/react'
import { Transition } from '@headlessui/react'
import { useForm, usePage } from '@inertiajs/react'

import Alert from '@/Shared/Alert'
import Avatar from '@/Shared/Avatar'
import Button from '@/Shared/Button'
import Checkbox from '@/Shared/Forms/Checkbox'
import Helpers from '@/utils/helpers'
import Select from '@/Shared/Forms/Select'

export default ({ activeContactId, contact, last, position, settings, transaction, onAdding, onClose, onError }) => {
  const { auth, roles: originalRoles } = usePage().props
  const [adding, setAdding] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [isTeam, setIsTeam] = useState(false)
  const [roles, setRoles] = useState(originalRoles)
  const associates = transaction.contacts.filter((tc) => tc.contact_type === 'Associate')
  const currentRole =
    contact.type === 'Controller'
      ? roles.filter((role) => role.value === 'Escrow Agent')
      : settings?.roles && roles.find((r) => r.value === settings.roles[0])

  const existingContact = transaction.contacts.find(
    (c) =>
      (c.contact ? c.contact.id : c.id) == contact.contact_id ||
      (c.contact ? c.contact.id : c.id) == contact.id ||
      c.followers?.find((f) => f.id == contact.id),
  )

  const parties = [
    { value: 'Buyer', label: 'Buyer' },
    { value: 'Seller', label: 'Seller' },
  ]
  const permissions = [
    { value: 'view', label: 'Viewer' },
    { value: 'edit', label: 'Editor' },
    { value: 'admin', label: 'Administrator' },
  ]

  const form = useForm({
    id: contact.id,
    type: contact.type,
    roles: currentRole ? (contact.roles || []).concat(currentRole) : contact.roles || [],
    permissions: 'edit',
    party_representing:
      contact.type === 'Controller'
        ? transaction.type
        : settings?.group
          ? parties.filter((p) => p.value === settings.group)[0].value
          : existingContact?.party_representing[0] || transaction.type,
    commission_splits: [],
    follow: contact.type !== 'Contact' && !contact.following,
    brokerage_split_auto_distribute: true,
  })

  const { clearErrors, data, errors, setData, setError } = form

  useEffect(() => checkIfTeam(), [data.party_representing])
  useEffect(() => filterRoles(), [data.roles, data.party_representing])

  useEffect(() => {
    if (adding) {
      let hasError = false
      let totalShare = data.commission_splits.reduce((carry, split) => carry + split.commission_pct, 0)
      let totalCap = data.commission_splits.reduce((carry, split) => carry + split.cap_pct, 0)

      if (totalShare != 100) {
        setError('commission_splits', `The total of all splits must add up to 100%. (Current: ${totalShare}%)`)
        hasError = true
      } else {
        clearErrors('commission_splits')
      }

      if (!data.brokerage_split_auto_distribute && totalCap != 100) {
        setError('brokerage_splits', `The total of all brokerage-side splits must add up to 100%. (Current: ${totalCap})`)
        hasError = true
      } else {
        clearErrors('brokerage_splits')
      }

      setDisabled(hasError)
    }
  }, [data.commission_splits])

  useEffect(() => {
    if (isTeam) {
      let associatesCount = associates.length + 1
      let equalShare = Helpers.numberPrecision(100 / associatesCount, 2)
      let combinedShares = Helpers.numberPrecision(equalShare * associatesCount, 2)
      let remainder = Helpers.round(100 - combinedShares, 2)

      setData({
        ...data,
        roles: (data.roles || [])
          .filter((r) => r.value !== `${transaction.type}-Agent`)
          .concat(originalRoles.find((r) => r.value === `${transaction.type}-Agent`)),
        commission_splits: Array.from(associates, (associate) => ({
          id: associate.id,
          full_name: associate.full_name,
          is_transaction_owner: associate.is_transaction_owner,
        }))
          .concat({ contact_id: contact.id, full_name: contact.name })
          .map((associate) => {
            let commission_pct = associate.is_transaction_owner ? Math.round((equalShare + remainder) * 1e12) / 1e12 : equalShare

            return {
              ...associate,
              commission_pct: commission_pct,
              cap_pct: commission_pct,
            }
          }),
      })
    }
  }, [isTeam])

  useEffect(() => {
    if (activeContactId != contact.id) {
      setAdding(false)
    }
  }, [activeContactId])

  useEffect(() => {
    if (errors.id) {
      onError({ contactExists: errors.id })
    }
  }, [errors])

  const checkIfTeam = () => {
    if (data.type === 'Associate' && auth.user.type === 'Associate') {
      let myContact = transaction.contacts.find((c) => c.is_me)
      if (myContact) {
        setIsTeam(myContact.party_representing[0] === data.party_representing)
      }
    }
  }

  const getEligibleRoles = () => {
    let eligibleRoles = roles

    if (contact.type === 'Controller') {
      eligibleRoles = eligibleRoles.filter((role) => role.value === 'Escrow Agent')
    } else if (contact.type === 'Associate') {
      eligibleRoles = eligibleRoles.filter((role) =>
        ['Buyer', 'Buyer-Agent', 'Seller', 'Seller-Agent'].filter((r) => r !== transaction.type).some((r) => r === role.value),
      )
    }

    // remove Escrow Agent role if it is already assigned
    if (transaction.contacts.find((contact) => contact.roles.find((role) => role.name === 'Escrow Agent'))) {
      eligibleRoles = eligibleRoles.filter((role) => role.value !== 'Escrow Agent')
    }

    // Filter roles when party representing is known
    if (data.party_representing) {
      eligibleRoles = eligibleRoles.filter((role) => !role.value.includes(data.party_representing === 'Buyer' ? 'Seller' : 'Buyer'))
    }

    return eligibleRoles
  }

  const getSplitName = (split) => {
    let name = contact.name
    let transactionContact = split.id && associates.find((tc) => tc.id == split.id)
    if (transactionContact) {
      name = transactionContact.contact
        ? `${transactionContact.contact.first_name} ${transactionContact.contact.last_name}`
        : transactionContact.full_name
    }

    return name + ' '
  }

  const filterRoles = () => {
    let activeKeyRoles = data.roles?.filter((r) => ['Buyer', 'Seller'].indexOf(r.value) >= 0)
    setRoles(
      activeKeyRoles?.length > 0
        ? originalRoles.filter((r) => activeKeyRoles.find((ar) => r == ar) || ['Buyer', 'Seller'].indexOf(r.value) < 0)
        : originalRoles,
    )
  }

  const roleChanged = (selected) => {
    clearErrors('roles')

    const [lastItem] = selected ? selected.slice(-1) : []
    const keyRoleWasSelected = lastItem && ['Buyer', 'Seller'].some((keyRole) => lastItem.value === keyRole)

    // Remove key roles if last selected item wasn't a key role
    if (selected && !keyRoleWasSelected) {
      selected = selected.filter((s) => !['Buyer', 'Seller'].some((keyRole) => s.value === keyRole))
    }

    setData({
      ...data,
      roles: keyRoleWasSelected ? [lastItem] : selected,
      party_representing: keyRoleWasSelected ? lastItem.value : data.party_representing,
    })
  }

  const partyRepresentingChanged = (selected) => {
    clearErrors('party_representing')

    setData({
      ...data,
      roles: currentRole && !data.roles && currentRole.value.includes(selected.value) ? [currentRole] : switchPartyRoles(selected?.value),
      party_representing: selected?.value,
    })
  }

  const switchPartyRoles = (newParty) => {
    if (data.roles == undefined) return []

    let partyRoles = data.roles.filter((role) => role.value?.includes(data.party_representing))

    return data.roles
      .filter((role) => !partyRoles.find((pr) => pr.value == role.value))
      .concat(
        partyRoles.map((role) => {
          let match = roles.find((r) => r.value == role.value.replace(data.party_representing, newParty))
          if (match) {
            return { ...match, name: match.value }
          }
        }),
      )
  }

  const assignRole = () => {
    setDisabled(true)

    router.patch(
      route('transactions.contacts.update', { transaction: transaction.id, transactionContact: existingContact.id }),
      {
        party_representing: [data.party_representing],
        roles: existingContact.roles
          .reduce(
            (roles, role) =>
              roles.concat({
                name: role.party_representing ? `${role.party_representing}-${role.name}` : role.name,
                primary: role.primary,
              }),
            [],
          )
          .concat({
            name: currentRole.value,
          }),
      },
      {
        onSuccess: () => {
          onClose()
          setTimeout(() => {
            setDisabled(false)
          }, 300)
        },
      },
    )
  }

  const submit = (event) => {
    event.preventDefault()

    setDisabled(true)

    router.post(
      route('transactions.contacts.store', transaction.id),
      {
        ...data,
        party_representing: [data.party_representing],
        roles: data.roles?.map((s) => ({ name: s.value })),
      },
      {
        onSuccess: () => {
          clearErrors()

          onClose()

          setTimeout(() => {
            setAdding(false)
          }, 300)
        },
        onFinish: () => {
          setTimeout(() => {
            setDisabled(false)
          }, 300)
        },
      },
    )
  }

  return (
    <div
      className={classNames(
        'relative transition-all duration-100 ease-in-out',
        adding
          ? 'z-50 -mx-2 -mb-1 -mt-2 rounded-lg border-2 border-gray-400 shadow-md'
          : [
              'border-gray-300',
              {
                'rounded-b-lg': position == 1,
                'border-b border-l border-r': !last,
                'rounded-t-lg border': last,
                border: position == 1 && last,
              },
            ],
      )}
    >
      <div>
        <div
          className={classNames('relative z-0 flex items-start justify-start bg-white p-5', {
            'rounded-b-lg': position == 1,
            'rounded-t-lg': adding || last,
          })}
        >
          <div className="flex flex-grow flex-wrap justify-center gap-3 text-left sm:justify-start">
            <div className="w-18 flex flex-col items-center justify-center gap-1">
              <Avatar contact={contact} height="h-14" width="w-14" />

              {contact.following && (
                <div
                  className={classNames(
                    'mt-0.5 inline-flex items-center whitespace-nowrap rounded-full px-2.5 py-1.5 text-sm font-medium uppercase leading-none',
                    contact.type === 'Contact' ? 'bg-gray-200 bg-opacity-75 text-gray-800' : 'bg-purple-100 text-purple-800',
                  )}
                >
                  <span>{contact.type === 'Contact' ? 'Contact' : 'PRO'}</span>
                </div>
              )}
            </div>

            <div className="mb-2 flex flex-1 flex-col justify-center leading-snug text-gray-600 sm:mb-0">
              <div className="font-semibold text-blue-500">{contact.name}</div>

              {contact.company && (
                <div className="font-medium">{contact.type === 'Associate' ? 'Better Living Real Estate' : contact.company}</div>
              )}

              {contact.type !== 'Controller' && contact.industry && <div>{contact.industry}</div>}

              {existingContact?.roles && (
                <div className="mt-0.5 flex flex-wrap gap-1">
                  {existingContact.roles?.map((role, index) => (
                    <span
                      className="inline-block rounded bg-blue-200 bg-opacity-40 px-1.5 py-1 text-sm font-medium leading-none text-blue-700"
                      key={index}
                    >
                      <span className="whitespace-pre-line leading-none">
                        {roles.find((r) => r.value === `${role.party_representing}-${role.name}` || r.value === role.name)?.label ||
                          role.name}
                      </span>
                    </span>
                  ))}
                </div>
              )}
            </div>

            <div className={classNames('flex justify-end self-stretch sm:w-auto md:pl-4', adding ? 'items-start' : 'items-center')}>
              {existingContact ? (
                <div className="space-y-3">
                  <div className="flex items-center justify-end">
                    <i
                      className={classNames(
                        'pr-2 text-lg leading-none',
                        existingContact.pending ? 'fas fa-user-clock text-blue-500' : 'fas fa-check text-green-600',
                      )}
                    ></i>
                    {existingContact.pending ? 'Pending' : 'Added'}
                  </div>

                  {currentRole &&
                    !existingContact.roles.find((role) => ['Buyer', 'Seller'].indexOf(role.name) >= 0) &&
                    contact.type !== 'Associate' &&
                    (['Buyer', 'Seller'].indexOf(currentRole.label) < 0 || existingContact.roles.length == 0) && (
                      <Button type="submit" theme="outline-sm" disabled={disabled} onClick={() => assignRole()}>
                        <div className="relative flex w-full items-center justify-center font-medium leading-none">
                          Assign {currentRole.label} Role
                        </div>
                      </Button>
                    )}
                </div>
              ) : adding ? (
                <Button theme="icon" onClick={() => setAdding(false)}>
                  <span className="sr-only">Close panel</span>
                  <i className="fal fa-times text-2xl leading-none text-opacity-80"></i>
                </Button>
              ) : (
                <Button theme="outline-sm" onClick={() => setAdding(true)}>
                  Add
                  <div className="flex h-5 w-5 justify-end leading-none">
                    <i className="fas fa-angle-down text-base" aria-hidden="true"></i>
                  </div>
                </Button>
              )}
            </div>
          </div>
        </div>

        <Transition
          show={adding}
          enter="transition ease duration-300 transform"
          enterFrom="-translate-y-full"
          enterTo="translate-y-0"
          beforeEnter={() => onAdding(contact.id)}
        >
          <form id="addContact" className="relative z-0 rounded-b-md border-t border-gray-300 bg-gray-100 p-6" onSubmit={submit}>
            <ul role="list" className="divide-y divide-gray-300 rounded-md border border-gray-300 bg-white">
              <li className={classNames('flex items-center justify-between py-3 pl-3 pr-4', errors?.roles && 'bg-red-100')}>
                <div className="ml-4 flex-grow sm:ml-4 sm:flex sm:items-center sm:justify-between">
                  <div className="mb-1.5 text-left sm:mb-0 sm:flex sm:flex-1 sm:flex-wrap sm:items-center">
                    <span className="flex-1 font-semibold text-gray-800">Define role(s) on closing report</span>
                    {errors.roles && <div className="w-full text-red-700">{errors.roles}</div>}
                  </div>
                  <div className="flex shrink-0 space-x-4 sm:ml-4">
                    <Select
                      classes="w-full sm:w-80"
                      name="roles"
                      options={getEligibleRoles()}
                      placeholder="None"
                      value={data.roles}
                      isClearable={false}
                      onChange={(selected) => roleChanged(selected)}
                      multiple
                      creatable
                      disabled={
                        contact.type === 'Controller' || (contact.type === 'Associate' && data.party_representing === transaction.type)
                      }
                    />
                  </div>
                </div>
              </li>
              {contact.type !== 'Controller' &&
                (!data.roles || data.roles.filter((r) => ['Buyer', 'Seller'].indexOf(r.value) >= 0).length == 0) && (
                  <li
                    className={classNames('flex items-center justify-between py-3 pl-3 pr-4', errors?.party_representing && 'bg-red-100')}
                  >
                    <div className="ml-4 flex-grow sm:ml-4 sm:flex sm:items-center sm:justify-between">
                      <div className="mb-1.5 text-left sm:mb-0 sm:flex sm:flex-1 sm:flex-wrap sm:items-center">
                        <span className="flex-1 font-semibold text-gray-800">
                          Which party is this contact representing? <span className="text-red-700">*</span>
                        </span>
                        {errors.party_representing && <div className="w-full text-red-700">{errors.party_representing}</div>}
                      </div>
                      <div className="flex flex-shrink-0 space-x-4 sm:ml-4">
                        <Select
                          classes="w-full sm:w-80"
                          name="party_representing"
                          options={parties}
                          placeholder="Choose one"
                          value={data.party_representing}
                          isClearable={false}
                          onChange={(selected) => partyRepresentingChanged(selected)}
                        />
                      </div>
                    </div>
                  </li>
                )}
              {!['Contact', 'Controller'].some((type) => type === contact.type) && !contact.following && (
                <li className="flex items-center justify-center bg-gray-50 py-3 pl-3 pr-4">
                  <Checkbox
                    label="Follow this contact"
                    name="follow"
                    value={data.follow}
                    onChange={(checked) => setData({ ...data, follow: checked })}
                  />
                </li>
              )}
            </ul>

            {isTeam && (
              <div className="mt-5">
                <hr className="border-gray-300" />

                <div className="my-3">
                  <h3 className="mb-1.5 block text-lg font-medium">Team Member Settings</h3>

                  <ul role="list" className="divide-y divide-gray-300 rounded-md border border-gray-300 bg-white">
                    <li className={classNames('flex items-center justify-between py-3 pl-3 pr-4', errors?.permissions && 'bg-red-100')}>
                      <div className="ml-4 flex-grow sm:ml-4 sm:flex sm:items-center sm:justify-between">
                        <div className="mb-1.5 text-left sm:mb-0 sm:flex sm:flex-1 sm:flex-wrap sm:items-center">
                          <span className="flex-1 font-semibold text-gray-800">
                            Specify permissions for this contact <span className="text-red-700">*</span>
                          </span>
                          {errors.permissions && <div className="w-full text-red-700">{errors.permissions}</div>}
                        </div>
                        <div className="flex flex-shrink-0 space-x-4 sm:ml-4">
                          <Select
                            classes="w-full sm:w-80"
                            name="permissions"
                            options={permissions}
                            placeholder="Choose one"
                            value={data.permissions}
                            isClearable={false}
                            onChange={(selected) => {
                              clearErrors('permissions')
                              setData({ ...data, permissions: selected ? selected.value : '' })
                            }}
                          />
                        </div>
                      </div>
                    </li>
                    <li className="py-3 pl-3 pr-4">
                      <div className="ml-4 sm:flex sm:justify-between sm:gap-6">
                        <div className="flex-1">
                          <span className="font-semibold text-gray-800">
                            Associate-Side Splits: <span className="text-red-700">*</span>
                          </span>

                          <p className="text-gray-500">Changes to Associate-Side splits requires approval from each affected Associate.</p>

                          {errors?.commission_splits && <div className="mt-1 font-semibold text-red-600">* {errors.commission_splits}</div>}
                        </div>

                        <div className="mb-1 space-y-4 sm:w-80">
                          {data.commission_splits.map((split, index) => (
                            <div key={index}>
                              <div className="text-sm font-semibold uppercase text-blue-500">
                                {getSplitName(split)}
                                <span className="text-red-700">*</span>
                              </div>
                              <div className="relative flex w-full">
                                <input
                                  autoComplete="off"
                                  autoCorrect="false"
                                  className={classNames(
                                    'font-md transition-border relative block h-11 w-full rounded-l px-4 py-2 placeholder-gray-400 outline-none duration-150 ease-in-out',
                                    errors?.commission_splits
                                      ? 'border-transparent ring-2 ring-red-500 hover:ring-red-400 focus:ring-red-700'
                                      : 'border border-gray-300 hover:border-gray-400 focus:border-transparent focus:ring-2 focus:ring-primary-500',
                                  )}
                                  type="number"
                                  value={split.commission_pct}
                                  onChange={(e) => {
                                    clearErrors('commission_splits')
                                    setData({
                                      ...data,
                                      commission_splits: data.commission_splits.map((s) => {
                                        if (s.id == split.id || (s.contact_id != undefined && s.contact_id == split.contact_id)) {
                                          return {
                                            ...s,
                                            commission_pct: parseFloat(e.target.value),
                                            cap_pct: data.brokerage_split_auto_distribute ? parseFloat(e.target.value) : s.cap_pct,
                                          }
                                        }

                                        return s
                                      }),
                                    })
                                  }}
                                  onFocus={(e) => e.target.select()}
                                />

                                <span
                                  className={classNames(
                                    'flex w-20 items-center justify-center rounded-r border-b border-r border-t text-sm font-semibold transition-all duration-150 ease-in-out',
                                    errors?.commission_splits
                                      ? 'bg-red-100 text-red-500 ring-2 ring-red-500'
                                      : 'border-gray-300 bg-gray-200 text-gray-700',
                                  )}
                                >
                                  <i className="fal fa-percent text-lg"></i>
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </li>
                    <li className={classNames('flex justify-between py-3 pl-3 pr-4', errors?.permissions && 'bg-red-100')}>
                      <div className="ml-4 flex-grow gap-6 sm:ml-4 sm:flex sm:justify-between">
                        <div className="mb-1.5 flex-1 text-left sm:mb-0">
                          <span className="flex flex-1 items-center gap-1.5 font-semibold text-gray-800">
                            Brokerage-Side Splits <span className="text-red-600">*</span>
                          </span>

                          {errors?.brokerage_splits && <div className="mt-1 font-semibold text-red-600">* {errors.brokerage_splits}</div>}

                          <div className="pt-4">
                            <Checkbox
                              name="brokerage_split_auto_distribute"
                              label="Distribute Automatically"
                              description="CAP will be applied to all Associates according to their Associate-side split %."
                              value={data.brokerage_split_auto_distribute}
                              onChange={(checked) =>
                                setData({
                                  ...data,
                                  brokerage_split_auto_distribute: checked,
                                  ...(checked
                                    ? {
                                        commission_splits: data.commission_splits.map((s) => ({
                                          ...s,
                                          cap_pct: s.commission_pct,
                                        })),
                                      }
                                    : {}),
                                })
                              }
                            />
                          </div>
                        </div>

                        <div className="flex w-full flex-col gap-4 sm:w-80">
                          {data.commission_splits?.map((split, index) => (
                            <div key={index}>
                              <div className="text-sm font-semibold uppercase text-blue-500">
                                {getSplitName(split)}
                                <span className="text-red-700">*</span>
                              </div>
                              <div className="relative flex w-full">
                                <input
                                  name={`brokerage_split_${index}`}
                                  autoComplete="off"
                                  autoCorrect="false"
                                  className={classNames(
                                    'font-md transition-border relative block h-11 w-full rounded-l px-4 py-2 placeholder-gray-400 outline-none duration-150 ease-in-out',
                                    data.brokerage_split_auto_distribute ? 'cursor-not-allowed bg-gray-200' : '',
                                    errors?.brokerage_splits
                                      ? 'border-transparent ring-2 ring-red-500 hover:ring-red-400 focus:ring-red-700'
                                      : 'border border-gray-300 hover:border-gray-400 focus:border-transparent focus:ring-2 focus:ring-primary-500',
                                  )}
                                  spellCheck="false"
                                  type="number"
                                  value={split.cap_pct}
                                  onChange={(e) => {
                                    clearErrors('brokerage_splits')
                                    setData({
                                      ...data,
                                      commission_splits: data.commission_splits.map((s) => {
                                        if (s.id == split.id || (s.contact_id != undefined && s.contact_id == split.contact_id)) {
                                          return {
                                            ...s,
                                            cap_pct: parseFloat(e.target.value),
                                          }
                                        }

                                        return s
                                      }),
                                    })
                                  }}
                                  onFocus={(e) => e.target.select()}
                                  disabled={data.brokerage_split_auto_distribute}
                                />

                                <span
                                  className={classNames(
                                    'flex w-20 items-center justify-center rounded-r border-b border-r border-t text-sm font-semibold transition-all duration-150 ease-in-out',
                                    errors?.brokerage_splits
                                      ? 'bg-red-100 text-red-500 ring-2 ring-red-500'
                                      : 'border-gray-300 bg-gray-200 text-gray-700',
                                  )}
                                >
                                  <i className="fal fa-percent text-lg"></i>
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <Alert
                  type="warning"
                  heading="Team Member Alert"
                  subtext={
                    <p className="font-normal">
                      You are about to add <b>{contact.name}</b> as a member of your Team. Continuing with this action will grant them{' '}
                      <span className="font-semibold uppercase text-blue-500">{data.permissions}</span> access to this transaction.
                    </p>
                  }
                />
              </div>
            )}

            <div className="mt-6 flex justify-end">
              <Button type="submit" theme="solid" form="addContact" disabled={disabled}>
                <div className="relative flex w-full items-center justify-center py-1 font-medium leading-none">
                  {isTeam ? (
                    <span>
                      <i className="fas fa-users mr-2"></i> Invite to My Team
                    </span>
                  ) : (
                    <span>
                      <i className="fas fa-user-plus mr-2"></i> Add
                    </span>
                  )}
                </div>
              </Button>
            </div>
          </form>
        </Transition>
      </div>
    </div>
  )
}
